<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab3TableDown>
            <div>
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.add"
                icon="plus-circle"
                @click="tableRowAdd(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.edit"
                icon="minus-circle"
                @click="tableRowEdit(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.table_row_delete"
                icon="minus-circle"
                @click="tableRowRemove(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.product_batch"
                icon="plus-circle"
                @click="tableRowProductBatch(obj)"
              />
              <!-- ロック -->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.table_row_lock"
                icon="minus-circle"
                @click="tableRowLock(obj)"
              />
              <!-- ロック解除 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelected"
                :columnInfo="detailCtrlInfo.table_row_lock_cancel"
                icon="minus-circle"
                @click="tableRowUnLock(obj)"
              />
            </div>
            <!-- テーブル -->
            <b-table
              data-class="base-table"
              class="table table-bordered base-table"
              bordered
              hover
              no-border-collapse
              responsive
              :selectable="true"
              small
              sticky-header
              outlined
              select-mode="range"
              @row-selected="onRowSelected"
              @row-clicked="onRowClicked"
              :fields="fields"
              :items="tableItems"
              v-bind="$attrs"
              v-on="$listeners"
            >
            </b-table>
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>

    <!-- 郵便番号モーダル -->
    <!-- 市区町村モーダル -->
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="order-department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 受注オーダー明細編集モーダル -->
    <ReceiveOrderDetailEdit
      id="receive-order-detail-edit"
      type="entry"
      selectMode="single"
      :getDatas.sync="selectedReceiveOrderDetail"
      @after-close-set="afterCloseSetOrderDetail"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 販売情報モーダル -->
    <SaleModal
      id="sale-modal"
      type="entry"
      selectMode="single"
      :getDatas.sync="saleModalCondition"
      @after-close-set="afterCloseSetSale"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 荷姿パターンマスタモーダル -->
    <ProductPatternModal
      id="product-pattern-modal"
      type="entry"
      @after-close-set="afterCloseSetProductPattern"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />

    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
      detail-info-modal
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import ReceiveOrderInfo from './ReceiveOrderInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import { DEPARTMENT_KIND, INTEGER_MAX_MIN, SLOT_NAME } from '@/common/const.js'
import {
  init,
  goAllRemove,
  goSaleInfo,
  remove,
  clear,
  setPlaceInfo,
  unsetPlaceInfo,
  displayTotalValue,
  setOrderDetail,
  goRegister,
  register,
  tableRowRemove,
  tableRowEdit,
  tableRowAdd,
  tableRowProductBatch,
  setProductPatternInfos,
  tableRowLock,
  tableRowUnLock,
} from '@/order/receiveorder/ReceiveOrderDetail.js'

export default {
  name: 'receiveOrderDetail',

  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    ReceiveOrderDetailEdit: () =>
      import('@/order/receiveorder/ReceiveOrderDetailEdit.vue'),
    SaleModal: () => import('@/order/sale/SaleModal.vue'),
    ProductPatternModal: () =>
      import('@/master/productPattern/ProductPatternModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    orderId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      tableItems: [],
      selectedItem: [],
      formData: new ReceiveOrderInfo(),
      selectedReceiveOrderDetail: [],
      placeModalKbn: -1,
      departmentModalKbn: -1,
      obj: this,
      registerMessage: null,
      oriData: new ReceiveOrderInfo(),
      removeMessage: null,
      detailScreenKbn: 0,
      copyTableData: [],
      detailRowIndex: -1,
      oriTableItems: [],
      departmentModalSearchCondition: {},
      saleModalCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.RECEIVE_ORDER_DETAIL
      )('detail_ctrl_info')

      if (this.screenKbn != this.DETAIL_SCREEN_KBN.DETAIL) {
        info.delete.visibility = this.VISIBILITY.DISABLE
      }
      return info
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.RECEIVE_ORDER_DETAIL
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.RECEIVE_ORDER_DETAIL
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // 削除ボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.delete,
            onClickCallback: () => {
              goAllRemove(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 販売情報
          {
            variant: 'success',
            icon: 'list-alt',
            columnInfo: this.detailCtrlInfo.sale,
            disabled: !this.isDetail,
            onClickCallback: () => {
              goSaleInfo(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 受注オーダタブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 受注オーダID
              type: 'text',
              id: 'orderId',
              columnInfo: this.detailInfo.order_id,
            },
            {
              // 受付番号
              type: 'text',
              id: 'receiptNo',
              columnInfo: this.detailInfo.receipt_no,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              maxLength: 30,
            },
            {
              // ブロック
              type: 'select',
              id: 'blockCode',
              columnInfo: this.detailInfo.block_code,
              options: getListOptions(this.MASTER_CODE.BLOCK),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 出発可能日時 (Begin)
              type: 'dateTime',
              dateId: 'departureBeginDate',
              timeId: 'departureBeginTime',
              dateColumnInfo: this.detailInfo.departure_begin_date,
              timeColumnInfo: this.detailInfo.departure_begin_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 出発可能日時 (End)
              type: 'dateTime',
              dateId: 'departureEndDate',
              timeId: 'departureEndTime',
              dateColumnInfo: this.detailInfo.departure_end_date,
              timeColumnInfo: this.detailInfo.departure_end_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 出荷場所
              type: 'referenceCodeName',
              codeId: 'departurePlaceCode',
              nameId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_code,
              nameColumnInfo: this.detailInfo.departure_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_departure_place,
              codeMaxLength: 30,
              nameMaxLength: 50,
              codeRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 出荷場所住所
              type: 'text',
              id: 'departurePostalCode',
              columnInfo: this.detailInfo.departure_postal_code,
            },
            // {
            //   // 出荷場所住所
            //   // cityCode
            //   // 画面非表示
            //   type: 'text',
            //   id: 'departureCityCode',
            //   columnInfo: this.detailInfo.departure_city_code,
            //   rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            // },
            {
              // 出荷場所住所
              type: 'text',
              id: 'departureAddress1',
              columnInfo: this.detailInfo.departure_address_1,
            },
            {
              // 出荷場所住所
              type: 'text',
              id: 'departureAddress2',
              columnInfo: this.detailInfo.departure_address_2,
            },
            {
              // 出発場所指定フラグ
              type: 'select',
              id: 'departurePlaceAssignFlag',
              columnInfo: this.detailInfo.departure_place_assign_flag,
              options: getListOptions(this.MASTER_CODE.PLACE_ASSIGN_FLAG),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 出発時間指定区分
              type: 'select',
              id: 'departureTimeAssignKind',
              columnInfo: this.detailInfo.departure_time_assign_kind,
              options: getListOptions(this.MASTER_CODE.TIME_ASSIGN_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 出発時間厳格度
              type: 'select',
              id: 'departureTimeStrict',
              columnInfo: this.detailInfo.departure_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 出荷場所名(代表)
              type: 'text',
              id: 'departureRepresentativePlaceName',
              columnInfo: this.detailInfo.departure_representative_place_name,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 出荷最寄りIC名
              type: 'text',
              id: 'departureIcPlaceName',
              columnInfo: this.detailInfo.departure_ic_place_name,
            },
            {
              // 搬入可能日時 (Begin)
              type: 'dateTime',
              dateId: 'arrivalBeginDate',
              timeId: 'arrivalBeginTime',
              dateColumnInfo: this.detailInfo.arrival_begin_date,
              timeColumnInfo: this.detailInfo.arrival_begin_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 搬入可能日時 (End)
              type: 'dateTime',
              dateId: 'arrivalEndDate',
              timeId: 'arrivalEndTime',
              dateColumnInfo: this.detailInfo.arrival_end_date,
              timeColumnInfo: this.detailInfo.arrival_end_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 搬入場所
              type: 'referenceCodeName',
              codeId: 'arrivalPlaceCode',
              nameId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_code,
              nameColumnInfo: this.detailInfo.arrival_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_arrival_place,
              codeMaxLength: 30,
              nameMaxLength: 50,
              codeRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 2
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 搬入場所住所
              type: 'text',
              id: 'arrivalPostalCode',
              columnInfo: this.detailInfo.arrival_postal_code,
            },
            // {
            //   // 搬入場所住所
            //   // cityCode
            //   // 画面非表示
            //   type: 'text',
            //   id: 'arrivalCityCode',
            //   columnInfo: this.detailInfo.arrival_city_code,
            //   rules: [this.INPUT_TYPE_CHECK.ZIPNUMERIC],
            // },
            {
              // 搬入場所住所
              type: 'text',
              id: 'arrivalAddress1',
              columnInfo: this.detailInfo.arrival_address_1,
            },
            {
              // 搬入場所住所
              type: 'text',
              id: 'arrivalAddress2',
              columnInfo: this.detailInfo.arrival_address_2,
            },
            {
              // 搬入時間指定区分
              type: 'select',
              id: 'arrivalTimeAssignKind',
              columnInfo: this.detailInfo.arrival_time_assign_kind,
              options: getListOptions(this.MASTER_CODE.TIME_ASSIGN_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 搬入時間厳格度
              type: 'select',
              id: 'arrivalTimeStrict',
              columnInfo: this.detailInfo.arrival_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 搬入場所名(代表)
              type: 'text',
              id: 'arrivalRepresentativePlaceName',
              columnInfo: this.detailInfo.arrival_representative_place_name,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 搬入最寄りIC名
              type: 'text',
              id: 'arrivalIcPlaceName',
              columnInfo: this.detailInfo.arrival_ic_place_name,
            },
            {
              // 総数量
              type: 'text',
              id: 'totalQuantity',
              columnInfo: this.detailInfo.total_quantity,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 総質量
              type: 'text',
              id: 'totalWeight',
              columnInfo: this.detailInfo.total_weight,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 総容量
              type: 'text',
              id: 'totalVolume',
              columnInfo: this.detailInfo.total_volume,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 荷主名
              type: 'referenceCodeName',
              codeId: 'ownerCode',
              nameId: 'ownerName',
              codeColumnInfo: this.detailInfo.owner_code,
              nameColumnInfo: this.detailInfo.owner_name,
              btnColumnInfo: this.detailInfo.sub_modal_owner,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 1
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.OWNER
                this.openSubModal(this, 'order-department-modal')
              },
            },

            {
              // 特約店名
              type: 'referenceCodeName',
              codeId: 'distributorCode',
              nameId: 'distributorName',
              codeColumnInfo: this.detailInfo.distributor_code,
              nameColumnInfo: this.detailInfo.distributor_name,
              btnColumnInfo: this.detailInfo.sub_modal_distributor,
              onClickCallback: () => {
                this.departmentModalKbn = 2
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.DISTRIBUTOR
                this.openSubModal(this, 'order-department-modal')
              },
            },

            {
              // 荷受先名
              type: 'referenceCodeName',
              codeId: 'consigneeCode',
              nameId: 'consigneeName',
              codeColumnInfo: this.detailInfo.consignee_code,
              nameColumnInfo: this.detailInfo.consignee_name,
              btnColumnInfo: this.detailInfo.sub_modal_consignee,
              onClickCallback: () => {
                this.departmentModalKbn = 3
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.CONSIGNEE
                this.openSubModal(this, 'order-department-modal')
              },
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // 注意事項 #2748
              type: 'text',
              id: 'warningMessage',
              columnInfo: this.detailInfo.warning_message,
            },
            {
              // 摘要01
              type: 'text',
              id: 'description01',
              columnInfo: this.detailInfo.description01,
              maxLength: 50,
            },
            {
              // 摘要02
              type: 'text',
              id: 'description02',
              columnInfo: this.detailInfo.description02,
              maxLength: 50,
            },
            {
              // 摘要03
              type: 'text',
              id: 'description03',
              columnInfo: this.detailInfo.description03,
              maxLength: 50,
            },
            {
              // 摘要04
              type: 'text',
              id: 'description04',
              columnInfo: this.detailInfo.description04,
              maxLength: 50,
            },
            {
              // 摘要05
              type: 'text',
              id: 'description05',
              columnInfo: this.detailInfo.description05,
              maxLength: 50,
            },
            // {
            //   // 配車計画グループ
            //   type: 'select',
            //   id: 'planningGroupCode',
            //   columnInfo: this.detailInfo.planning_group_code,
            //   options: getListOptions(this.MASTER_CODE.PLANNING_GROUP),
            //   onChangeCallback: () => {},
            // },
            {
              // 取消しフラグ
              type: 'select',
              id: 'canceled',
              columnInfo: this.detailInfo.canceled,
              options: getListOptions(this.MASTER_CODE.CANCELED),
              onChangeCallback: () => {},
            },
            {
              // 配車ステータス
              type: 'select',
              id: 'planningStatus',
              columnInfo: this.detailInfo.planning_status,
              options: getListOptions(this.MASTER_CODE.PLANNING_STATUS),
              onChangeCallback: () => {},
            },
            {
              //シミュレーション取込データフラグ
              type: 'select',
              id: 'isSimulationData',
              columnInfo: this.detailInfo.is_simulation_data,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.IS_SIMULATION_DATA),
              onChangeCallback: () => {},
            },
            {
              //処理状態
              type: 'select',
              id: 'processStatus',
              columnInfo: this.detailInfo.process_status,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.PROCESS_STATUS),
              onChangeCallback: () => {},
            },
            {
              // 受付元
              type: 'text',
              id: 'receptProcess',
              columnInfo: this.detailInfo.recept_process,
            },
            {
              // 指定運送会社
              type: 'referenceCodeName',
              codeId: 'assignCarrierCode',
              nameId: 'assignCarrierName',
              codeColumnInfo: this.detailInfo.assign_carrier_code,
              nameColumnInfo: this.detailInfo.assign_carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_assign_carrier,
              onClickCallback: () => {
                this.departmentModalKbn = 4
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.CARRIER
                this.openSubModal(this, 'order-department-modal')
              },
            },
            {
              // 輸送形態区分
              type: 'select',
              id: 'transportTypeKind',
              columnInfo: this.detailInfo.transport_type_kind,
              options: getListOptions(this.MASTER_CODE.TRANSPORT_TYPE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 輸送距離区分
              type: 'select',
              id: 'transportDistanceKind',
              columnInfo: this.detailInfo.transport_distance_kind,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.TRANSPORT_DISTANCE_KIND),
              onChangeCallback: () => {},
            },
            {
              // オーダーステータス
              type: 'select',
              id: 'orderStatus',
              columnInfo: this.detailInfo.order_status,
              options: getListOptions(this.MASTER_CODE.ORDER_STATUS),
              onChangeCallback: () => {},
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時表示
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時表示
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
        {
          // 受注オーダー予備タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 受注オーダー予備１
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１
              type: 'text',
              id: 'reserve11',
              columnInfo: this.detailInfo.reserve11,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２
              type: 'text',
              id: 'reserve12',
              columnInfo: this.detailInfo.reserve12,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３
              type: 'text',
              id: 'reserve13',
              columnInfo: this.detailInfo.reserve13,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１４
              type: 'text',
              id: 'reserve14',
              columnInfo: this.detailInfo.reserve14,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１５
              type: 'text',
              id: 'reserve15',
              columnInfo: this.detailInfo.reserve15,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１６
              type: 'text',
              id: 'reserve16',
              columnInfo: this.detailInfo.reserve16,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１７
              type: 'text',
              id: 'reserve17',
              columnInfo: this.detailInfo.reserve17,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１８
              type: 'text',
              id: 'reserve18',
              columnInfo: this.detailInfo.reserve18,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１９
              type: 'text',
              id: 'reserve19',
              columnInfo: this.detailInfo.reserve19,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２０
              type: 'text',
              id: 'reserve20',
              columnInfo: this.detailInfo.reserve20,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２１
              type: 'text',
              id: 'reserve21',
              columnInfo: this.detailInfo.reserve21,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２２
              type: 'text',
              id: 'reserve22',
              columnInfo: this.detailInfo.reserve22,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２３
              type: 'text',
              id: 'reserve23',
              columnInfo: this.detailInfo.reserve23,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２４
              type: 'text',
              id: 'reserve24',
              columnInfo: this.detailInfo.reserve24,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２５
              type: 'text',
              id: 'reserve25',
              columnInfo: this.detailInfo.reserve25,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２６
              type: 'text',
              id: 'reserve26',
              columnInfo: this.detailInfo.reserve26,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２７
              type: 'text',
              id: 'reserve27',
              columnInfo: this.detailInfo.reserve27,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２８
              type: 'text',
              id: 'reserve28',
              columnInfo: this.detailInfo.reserve28,
              maxLength: 200,
            },
            {
              // 受注オーダー予備２９
              type: 'text',
              id: 'reserve29',
              columnInfo: this.detailInfo.reserve29,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３０
              type: 'text',
              id: 'reserve30',
              columnInfo: this.detailInfo.reserve30,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３１
              type: 'text',
              id: 'reserve31',
              columnInfo: this.detailInfo.reserve31,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３２
              type: 'text',
              id: 'reserve32',
              columnInfo: this.detailInfo.reserve32,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３３
              type: 'text',
              id: 'reserve33',
              columnInfo: this.detailInfo.reserve33,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３４
              type: 'text',
              id: 'reserve34',
              columnInfo: this.detailInfo.reserve34,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３５
              type: 'text',
              id: 'reserve35',
              columnInfo: this.detailInfo.reserve35,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３６
              type: 'text',
              id: 'reserve36',
              columnInfo: this.detailInfo.reserve36,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３７
              type: 'text',
              id: 'reserve37',
              columnInfo: this.detailInfo.reserve37,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３８
              type: 'text',
              id: 'reserve38',
              columnInfo: this.detailInfo.reserve38,
              maxLength: 200,
            },
            {
              // 受注オーダー予備３９
              type: 'text',
              id: 'reserve39',
              columnInfo: this.detailInfo.reserve39,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４０
              type: 'text',
              id: 'reserve40',
              columnInfo: this.detailInfo.reserve40,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４１
              type: 'text',
              id: 'reserve41',
              columnInfo: this.detailInfo.reserve41,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４２
              type: 'text',
              id: 'reserve42',
              columnInfo: this.detailInfo.reserve42,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４３
              type: 'text',
              id: 'reserve43',
              columnInfo: this.detailInfo.reserve43,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４４
              type: 'text',
              id: 'reserve44',
              columnInfo: this.detailInfo.reserve44,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４５
              type: 'text',
              id: 'reserve45',
              columnInfo: this.detailInfo.reserve45,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４６
              type: 'text',
              id: 'reserve46',
              columnInfo: this.detailInfo.reserve46,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４７
              type: 'text',
              id: 'reserve47',
              columnInfo: this.detailInfo.reserve47,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４８
              type: 'text',
              id: 'reserve48',
              columnInfo: this.detailInfo.reserve48,
              maxLength: 200,
            },
            {
              // 受注オーダー予備４９
              type: 'text',
              id: 'reserve49',
              columnInfo: this.detailInfo.reserve49,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５０
              type: 'text',
              id: 'reserve50',
              columnInfo: this.detailInfo.reserve50,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５１
              type: 'text',
              id: 'reserve51',
              columnInfo: this.detailInfo.reserve51,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５２
              type: 'text',
              id: 'reserve52',
              columnInfo: this.detailInfo.reserve52,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５３
              type: 'text',
              id: 'reserve53',
              columnInfo: this.detailInfo.reserve53,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５４
              type: 'text',
              id: 'reserve54',
              columnInfo: this.detailInfo.reserve54,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５５
              type: 'text',
              id: 'reserve55',
              columnInfo: this.detailInfo.reserve55,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５６
              type: 'text',
              id: 'reserve56',
              columnInfo: this.detailInfo.reserve56,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５７
              type: 'text',
              id: 'reserve57',
              columnInfo: this.detailInfo.reserve57,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５８
              type: 'text',
              id: 'reserve58',
              columnInfo: this.detailInfo.reserve58,
              maxLength: 200,
            },
            {
              // 受注オーダー予備５９
              type: 'text',
              id: 'reserve59',
              columnInfo: this.detailInfo.reserve59,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６０
              type: 'text',
              id: 'reserve60',
              columnInfo: this.detailInfo.reserve60,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６１
              type: 'text',
              id: 'reserve61',
              columnInfo: this.detailInfo.reserve61,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６２
              type: 'text',
              id: 'reserve62',
              columnInfo: this.detailInfo.reserve62,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６３
              type: 'text',
              id: 'reserve63',
              columnInfo: this.detailInfo.reserve63,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６４
              type: 'text',
              id: 'reserve64',
              columnInfo: this.detailInfo.reserve64,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６５
              type: 'text',
              id: 'reserve65',
              columnInfo: this.detailInfo.reserve65,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６６
              type: 'text',
              id: 'reserve66',
              columnInfo: this.detailInfo.reserve66,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６７
              type: 'text',
              id: 'reserve67',
              columnInfo: this.detailInfo.reserve67,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６８
              type: 'text',
              id: 'reserve68',
              columnInfo: this.detailInfo.reserve68,
              maxLength: 200,
            },
            {
              // 受注オーダー予備６９
              type: 'text',
              id: 'reserve69',
              columnInfo: this.detailInfo.reserve69,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７０
              type: 'text',
              id: 'reserve70',
              columnInfo: this.detailInfo.reserve70,
              maxLength: 200,
            },

            {
              // 受注オーダー予備７１
              type: 'text',
              id: 'reserve71',
              columnInfo: this.detailInfo.reserve71,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７２
              type: 'text',
              id: 'reserve72',
              columnInfo: this.detailInfo.reserve72,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７３
              type: 'text',
              id: 'reserve73',
              columnInfo: this.detailInfo.reserve73,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７４
              type: 'text',
              id: 'reserve74',
              columnInfo: this.detailInfo.reserve74,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７５
              type: 'text',
              id: 'reserve75',
              columnInfo: this.detailInfo.reserve75,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７６
              type: 'text',
              id: 'reserve76',
              columnInfo: this.detailInfo.reserve76,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７７
              type: 'text',
              id: 'reserve77',
              columnInfo: this.detailInfo.reserve77,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７８
              type: 'text',
              id: 'reserve78',
              columnInfo: this.detailInfo.reserve78,
              maxLength: 200,
            },
            {
              // 受注オーダー予備７９
              type: 'text',
              id: 'reserve79',
              columnInfo: this.detailInfo.reserve79,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８０
              type: 'text',
              id: 'reserve80',
              columnInfo: this.detailInfo.reserve80,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８１
              type: 'text',
              id: 'reserve81',
              columnInfo: this.detailInfo.reserve81,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８２
              type: 'text',
              id: 'reserve82',
              columnInfo: this.detailInfo.reserve82,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８３
              type: 'text',
              id: 'reserve83',
              columnInfo: this.detailInfo.reserve83,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８４
              type: 'text',
              id: 'reserve84',
              columnInfo: this.detailInfo.reserve84,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８５
              type: 'text',
              id: 'reserve85',
              columnInfo: this.detailInfo.reserve85,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８６
              type: 'text',
              id: 'reserve86',
              columnInfo: this.detailInfo.reserve86,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８７
              type: 'text',
              id: 'reserve87',
              columnInfo: this.detailInfo.reserve87,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８８
              type: 'text',
              id: 'reserve88',
              columnInfo: this.detailInfo.reserve88,
              maxLength: 200,
            },
            {
              // 受注オーダー予備８９
              type: 'text',
              id: 'reserve89',
              columnInfo: this.detailInfo.reserve89,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９０
              type: 'text',
              id: 'reserve90',
              columnInfo: this.detailInfo.reserve90,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９１
              type: 'text',
              id: 'reserve91',
              columnInfo: this.detailInfo.reserve91,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９２
              type: 'text',
              id: 'reserve92',
              columnInfo: this.detailInfo.reserve92,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９３
              type: 'text',
              id: 'reserve93',
              columnInfo: this.detailInfo.reserve93,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９４
              type: 'text',
              id: 'reserve94',
              columnInfo: this.detailInfo.reserve94,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９５
              type: 'text',
              id: 'reserve95',
              columnInfo: this.detailInfo.reserve95,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９６
              type: 'text',
              id: 'reserve96',
              columnInfo: this.detailInfo.reserve96,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９７
              type: 'text',
              id: 'reserve97',
              columnInfo: this.detailInfo.reserve97,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９８
              type: 'text',
              id: 'reserve98',
              columnInfo: this.detailInfo.reserve98,
              maxLength: 200,
            },
            {
              // 受注オーダー予備９９
              type: 'text',
              id: 'reserve99',
              columnInfo: this.detailInfo.reserve99,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１００
              type: 'text',
              id: 'reserve100',
              columnInfo: this.detailInfo.reserve100,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０１
              type: 'text',
              id: 'reserve101',
              columnInfo: this.detailInfo.reserve101,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０２
              type: 'text',
              id: 'reserve102',
              columnInfo: this.detailInfo.reserve102,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０３
              type: 'text',
              id: 'reserve103',
              columnInfo: this.detailInfo.reserve103,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０４
              type: 'text',
              id: 'reserve104',
              columnInfo: this.detailInfo.reserve104,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０５
              type: 'text',
              id: 'reserve105',
              columnInfo: this.detailInfo.reserve105,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０６
              type: 'text',
              id: 'reserve106',
              columnInfo: this.detailInfo.reserve106,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０７
              type: 'text',
              id: 'reserve107',
              columnInfo: this.detailInfo.reserve107,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０８
              type: 'text',
              id: 'reserve108',
              columnInfo: this.detailInfo.reserve108,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１０９
              type: 'text',
              id: 'reserve109',
              columnInfo: this.detailInfo.reserve109,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１０
              type: 'text',
              id: 'reserve110',
              columnInfo: this.detailInfo.reserve110,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１１
              type: 'text',
              id: 'reserve111',
              columnInfo: this.detailInfo.reserve111,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１２
              type: 'text',
              id: 'reserve112',
              columnInfo: this.detailInfo.reserve112,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１３
              type: 'text',
              id: 'reserve113',
              columnInfo: this.detailInfo.reserve113,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１４
              type: 'text',
              id: 'reserve114',
              columnInfo: this.detailInfo.reserve114,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１５
              type: 'text',
              id: 'reserve115',
              columnInfo: this.detailInfo.reserve115,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１６
              type: 'text',
              id: 'reserve116',
              columnInfo: this.detailInfo.reserve116,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１７
              type: 'text',
              id: 'reserve117',
              columnInfo: this.detailInfo.reserve117,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１８
              type: 'text',
              id: 'reserve118',
              columnInfo: this.detailInfo.reserve118,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１１９
              type: 'text',
              id: 'reserve119',
              columnInfo: this.detailInfo.reserve119,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２０
              type: 'text',
              id: 'reserve120',
              columnInfo: this.detailInfo.reserve120,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２１
              type: 'text',
              id: 'reserve121',
              columnInfo: this.detailInfo.reserve121,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２２
              type: 'text',
              id: 'reserve122',
              columnInfo: this.detailInfo.reserve122,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２３
              type: 'text',
              id: 'reserve123',
              columnInfo: this.detailInfo.reserve123,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２４
              type: 'text',
              id: 'reserve124',
              columnInfo: this.detailInfo.reserve124,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２５
              type: 'text',
              id: 'reserve125',
              columnInfo: this.detailInfo.reserve125,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２６
              type: 'text',
              id: 'reserve126',
              columnInfo: this.detailInfo.reserve126,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２７
              type: 'text',
              id: 'reserve127',
              columnInfo: this.detailInfo.reserve127,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２８
              type: 'text',
              id: 'reserve128',
              columnInfo: this.detailInfo.reserve128,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１２９
              type: 'text',
              id: 'reserve129',
              columnInfo: this.detailInfo.reserve129,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３０
              type: 'text',
              id: 'reserve130',
              columnInfo: this.detailInfo.reserve130,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３１
              type: 'text',
              id: 'reserve131',
              columnInfo: this.detailInfo.reserve131,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３２
              type: 'text',
              id: 'reserve132',
              columnInfo: this.detailInfo.reserve132,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３３
              type: 'text',
              id: 'reserve133',
              columnInfo: this.detailInfo.reserve133,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３４
              type: 'text',
              id: 'reserve134',
              columnInfo: this.detailInfo.reserve134,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３５
              type: 'text',
              id: 'reserve135',
              columnInfo: this.detailInfo.reserve135,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３６
              type: 'text',
              id: 'reserve136',
              columnInfo: this.detailInfo.reserve136,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３７
              type: 'text',
              id: 'reserve137',
              columnInfo: this.detailInfo.reserve137,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３８
              type: 'text',
              id: 'reserve138',
              columnInfo: this.detailInfo.reserve138,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１３９
              type: 'text',
              id: 'reserve139',
              columnInfo: this.detailInfo.reserve139,
              maxLength: 200,
            },
            {
              // 受注オーダー予備１４０
              type: 'text',
              id: 'reserve140',
              columnInfo: this.detailInfo.reserve140,
              maxLength: 200,
            },
          ],
        },
        {
          // 受注オーダー明細タブ
          label: this.detailInfo.tab3.label,
          slot: SLOT_NAME.TAB3_TABLE_DOWN,
          inputComponents: [],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.RECEIVE_ORDER_DETAIL
      )(this.DOMAIN_NAME.FIELDS)
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
    /**
     * 詳細修正か
     */
    isDetail() {
      return this.screenKbn == this.DETAIL_SCREEN_KBN.DETAIL
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    setOrderDetail,
    setPlaceInfo,
    unsetPlaceInfo,
    register,
    remove,
    back,
    clear,
    tableRowRemove,
    displayTotalValue,
    tableRowEdit,
    tableRowAdd,
    tableRowProductBatch,
    setProductPatternInfos,
    tableRowLock,
    tableRowUnLock,

    afterCloseSetPlace(selectedItem) {
      setPlaceInfo(this, selectedItem)
    },
    afterCloseUnsetPlace() {
      unsetPlaceInfo(this)
    },
    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },
    onRowClicked(item, index) {
      this.detailRowIndex = index
    },

    afterCloseSetOrderDetail(info) {
      setOrderDetail(this, info)
    },
    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      // [department-modal] -> [order-department-modal]
      this.$bvModal.hide('order-department-modal')
      if (this.departmentModalKbn == 1) {
        this.obj.formData.ownerId = id
        this.obj.formData.ownerCode = code
        this.obj.formData.ownerName = name
      } else if (this.departmentModalKbn == 2) {
        this.obj.formData.distributorId = id
        this.obj.formData.distributorCode = code
        this.obj.formData.distributorName = name
      } else if (this.departmentModalKbn == 3) {
        this.obj.formData.consigneeId = id
        this.obj.formData.consigneeCode = code
        this.obj.formData.consigneeName = name
      } else if (this.departmentModalKbn == 4) {
        this.obj.formData.assignCarrierId = id
        this.obj.formData.assignCarrierCode = code
        this.obj.formData.assignCarrierName = name
      }
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      // [department-modal] -> [order-department-modal]
      this.$bvModal.hide('order-department-modal')
      if (this.departmentModalKbn == 1) {
        this.obj.formData.ownerId = null
        this.obj.formData.ownerCode = null
        this.obj.formData.ownerName = null
      } else if (this.departmentModalKbn == 2) {
        this.obj.formData.distributorId = null
        this.obj.formData.distributorCode = null
        this.obj.formData.distributorName = null
      } else if (this.departmentModalKbn == 3) {
        this.obj.formData.consigneeId = null
        this.obj.formData.consigneeCode = null
        this.obj.formData.consigneeName = null
      } else if (this.departmentModalKbn == 4) {
        this.obj.formData.assignCarrierId = null
        this.obj.formData.assignCarrierCode = null
        this.obj.formData.assignCarrierName = null
      }
    },

    // 販売情報
    afterCloseSetSale() {},

    /**
     * 荷姿パターンマスタから取得データ設定
     */
    afterCloseSetProductPattern(productPatternInfos) {
      setProductPatternInfos(this, productPatternInfos)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}
.data-group-role {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 14px;
}
.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
