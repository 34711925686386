<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 一括編集モーダル -->
    <!-- <ReceiveOrderEditModal
      id="edit-modal"
      type="entry"
      @after-close-set="afterCloseSetOderData"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    /> -->

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />

    <!-- CSV取込モーダル -->
    <UploadModal
      id="upload-modal"
      :columnInfos="fileInfo"
      @upload="setUploadFile"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import {
  init,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  goRemove,
  // goEdit,
  remove,
  download,
  upload,
} from '@/order/receiveorder/ReceiveOrderList.js'

const screenId = SCREEN_ID.RECEIVE_ORDER_LIST

export default {
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      placeModalKbn: -1,
      departmentModalKbn: -1,
      removeMessage: null,
      selectedRepresentativeReceiveOrderItems: [],
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    locale() {
      let lang = this.$store.getters['authentication/getLanguage']
      return lang
    },

    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 期間
            type: 'select',
            id: 'period',
            columnInfo: searchConditionInfo.period,
            options: getListOptions(this.MASTER_CODE.PERIOD_CODE),
            rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            onChangeCallback: () => {},
          },
          {
            // 期間
            type: 'dateFromTo',
            fromId: 'periodDateFrom',
            toId: 'periodDateTo',
            fromColumnInfo: searchConditionInfo.period_date_from,
            toColumnInfo: searchConditionInfo.period_date_to,
            locale: this.locale,
            fromRules: [
              this.INPUT_TYPE_CHECK.REQUIRED,
              this.INPUT_TYPE_CHECK.DATERANGE,
            ],
          },
          {
            // 受付番号
            type: 'text',
            id: 'receiptNo',
            columnInfo: searchConditionInfo.receipt_no,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            maxLength: 30,
          },
          {
            // 荷主
            type: 'referenceCodeName',
            codeId: 'ownerCode',
            nameId: 'ownerName',
            codeColumnInfo: searchConditionInfo.owner_code,
            nameColumnInfo: searchConditionInfo.owner_name,
            btnColumnInfo: searchConditionInfo.sub_modal_owner,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalKbn = 1
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.OWNER
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 出荷場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 1
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 搬入場所
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 2
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 特約店
            type: 'referenceCodeName',
            codeId: 'distributorCode',
            nameId: 'distributorName',
            codeColumnInfo: searchConditionInfo.distributor_code,
            nameColumnInfo: searchConditionInfo.distributor_name,
            btnColumnInfo: searchConditionInfo.sub_modal_distributor,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalKbn = 2
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.DISTRIBUTOR
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 荷受先
            type: 'referenceCodeName',
            codeId: 'consigneeCode',
            nameId: 'consigneeName',
            codeColumnInfo: searchConditionInfo.consignee_code,
            nameColumnInfo: searchConditionInfo.consignee_name,
            btnColumnInfo: searchConditionInfo.sub_modal_consignee,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalKbn = 3
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CONSIGNEE
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // オーダーステータス
            type: 'select',
            id: 'orderStatus',
            columnInfo: searchConditionInfo.order_status,
            rules: [this.INPUT_TYPE_CHECK.ONLY_ALPHANUMERIC],
            options: getListOptions(this.MASTER_CODE.ORDER_STATUS),
            onChangeCallback: () => {},
          },
          {
            // 配車ステータス
            type: 'checkboxgroup',
            id: 'planningStatus',
            columnInfo: searchConditionInfo.planning_status,
            options: getListOptions(this.MASTER_CODE.PLANNING_STATUS, false),
          },
          {
            // 受付元
            type: 'text',
            id: 'receptProcess',
            columnInfo: searchConditionInfo.recept_process,
          },
          {
            // 摘要01
            type: 'text',
            id: 'description01',
            columnInfo: searchConditionInfo.description01,
          },
          {
            // 摘要02
            type: 'text',
            id: 'description02',
            columnInfo: searchConditionInfo.description02,
          },
          {
            // 摘要03
            type: 'text',
            id: 'description03',
            columnInfo: searchConditionInfo.description03,
          },
          {
            // 摘要04
            type: 'text',
            id: 'description04',
            columnInfo: searchConditionInfo.description04,
          },
          {
            // 摘要05
            type: 'text',
            id: 'description05',
            columnInfo: searchConditionInfo.description05,
          },
          {
            // 取消データ表示
            type: 'select',
            id: 'canceled',
            columnInfo: searchConditionInfo.canceled,
            rules: [this.INPUT_TYPE_CHECK.ONLY_ALPHANUMERIC],
            options: getListOptions(this.MASTER_CODE.CANCELED),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew()
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goCopy(this)
            },
          },
          // 削除ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.delete,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goRemove(this)
            },
          },
          // // 一括編集ボタン
          // {
          //   variant: 'primary',
          //   icon: 'list',
          //   columnInfo: this.resultControlInfo.all_edit,
          //   disabled: !this.isSelected,
          //   onClickCallback: () => {
          //     goEdit(this, 'edit-modal')
          //   },
          // },
        ],
        // ボタングループ#2
        [
          // ﾌｧｲﾙ出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.file_print,
            onClickCallback: () => {
              openSubModal(this, 'download-modal')
            },
          },
          // ﾌｧｲﾙ取込ボタン
          {
            variant: 'primary',
            icon: 'upload',
            columnInfo: this.resultControlInfo.file_read,
            onClickCallback: () => {
              openSubModal(this, 'upload-modal')
            },
          },
        ],
      ]
    },

    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    search,
    clear,
    remove,
    download,
    upload,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      return {
        period: '10', //出荷(10)
        periodDateFrom: today,
        periodDateTo: today,
        receiptNo: null,
        ownerCode: null,
        ownerName: null,
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        distributorCode: null,
        distributorName: null,
        consigneeCode: null,
        consigneeName: null,
        orderStatus: null, //初期値：空白  ('0',正常)
        planningStatus: [],
        receptProcess: null,
        planningGroupCode: null,
        description01: null,
        description02: null,
        description03: null,
        description04: null,
        description05: null,
        canceled: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      if (this.departmentModalKbn == 1) {
        this.searchCondition.ownerCode = code
        this.searchCondition.ownerName = name
      } else if (this.departmentModalKbn == 2) {
        this.searchCondition.distributorCode = code
        this.searchCondition.distributorName = name
      } else {
        this.searchCondition.consigneeCode = code
        this.searchCondition.consigneeName = name
      }
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      if (this.departmentModalKbn == 1) {
        this.searchCondition.ownerCode = null
        this.searchCondition.ownerName = null
      } else if (this.departmentModalKbn == 2) {
        this.searchCondition.distributorCode = null
        this.searchCondition.distributorName = null
      } else {
        this.searchCondition.consigneeCode = null
        this.searchCondition.consigneeName = null
      }
    },
    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
      } else {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else {
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
      // this.retrieveReceiveOrders()
    },

    setUploadFile(file) {
      upload(this, file)
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
