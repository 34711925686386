import store from '@/store'
import router from '@/router'
import _ from 'lodash'
import { VISIBILITY } from '@/common/const.js'
import { openSubModal } from '@/common/Common.js'

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  if (obj.formData.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }
  openSubModal(obj, 'register-modal')
}

export function getBreadcrumbItems(beforeScreenRouteInfo, thisScreenId) {
  let breadcrumbItems = []
  breadcrumbItems.push({
    name: store.getters['init/getScreenName'](thisScreenId),
  })
  while (beforeScreenRouteInfo) {
    breadcrumbItems.push({
      name: store.getters['init/getScreenName'](
        beforeScreenRouteInfo.routeInfo.name
      ),
      routeInfo: beforeScreenRouteInfo.routeInfo,
      beforeScreenRouteInfo: beforeScreenRouteInfo.beforeScreenRouteInfo,
    })
    beforeScreenRouteInfo = beforeScreenRouteInfo.beforeScreenRouteInfo
  }

  return breadcrumbItems.reverse()
}

export function back(obj) {
  if (obj.beforeScreenRouteInfo) {
    let routeInfo = obj.beforeScreenRouteInfo.routeInfo
    routeInfo.params.beforeScreenRouteInfo =
      obj.beforeScreenRouteInfo.beforeScreenRouteInfo

    router.push(routeInfo)
  }
}

const visibleCtrlTypes = [
  VISIBILITY.UNDEFINED,
  VISIBILITY.VISIBLE,
  VISIBILITY.DISABLE,
]

export function getVisibleFromColumnInfo(columnInfo) {
  return visibleCtrlTypes.includes(columnInfo.visibility)
}

export function getDisableFromColumnInfo(columnInfo) {
  return columnInfo.visibility === VISIBILITY.DISABLE
}
