import { PROCESS_STATUS } from '@/common/const.js'
export default class receiveOrder {
  orderId = null
  receiptNo = null
  receiveDate = null
  blockCode = null
  blockName = null
  departureBeginDate = null
  departureBeginTime = null
  departureEndDate = null
  departureEndTime = null
  departurePlaceId = null
  departurePlaceCode = null
  departurePlaceName = null
  departurePlaceAssignFlag = null
  departurePostalCode = null
  departureCityCode = null
  departureAddress1 = null
  departureAddress2 = null
  departureTimeAssignKind = null
  departureTimeStrict = null
  departureIsOnetimeMasterName = null
  departureRepresentativePlaceName = null
  departureRepresentativePlaceCode = null
  departureRepresentativePlaceId = null
  arrivalBeginDate = null
  arrivalBeginTime = null
  arrivalEndDate = null
  arrivalEndTime = null
  arrivalPlaceId = 0
  arrivalPlaceCode = null
  arrivalPlaceName = null
  arrivalPostalCode = null
  arrivalCityCode = null
  arrivalAddress1 = null
  arrivalAddress2 = null
  arrivalTimeAssignKind = null
  arrivalTimeStrict = null
  arrivalIsOnetimeMasterName = null
  arrivalRepresentativePlaceName = null
  totalQuantity = 0
  totalWeight = 0
  totalVolume = 0
  ownerId = null
  ownerName = null
  ownerCode = null
  distributorId = null
  distributorName = null
  distributorCode = null
  consigneeId = null
  consigneeCode = null
  consigneeName = null
  // departureCondition = null
  // arrivalCondition = null
  note = null
  description01 = null
  description02 = null
  description03 = null
  description04 = null
  description05 = null
  canceled = '0'
  isSimulationData = '0'
  planningStatus = '10'
  orderStatus = '0' // 正常
  //orderEntryKind = 10
  receptProcess = 'NEUPLANET'
  planningGroupCode = null
  assignCarrierId = null
  assignCarrierName = null
  assignCarrierCode = null
  // assignVehicleId = null
  // assignVehicleName = null
  // assignVehicleCode = null
  vehicleId = null
  vehicleCode = null
  vehicleName = null
  //assignTripNo = 0
  //assingDropNo = 0
  autoPlanningExcludeFlag = null
  //orderKind = null
  //preloadKind = 0
  //preloadAssignDate = null
  //preloadBeginTime = null
  transportTypeKind = null
  transportDistanceKind = null
  warning_message = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  reserve11 = null
  reserve12 = null
  reserve13 = null
  reserve14 = null
  reserve15 = null
  reserve16 = null
  reserve17 = null
  reserve18 = null
  reserve19 = null
  reserve20 = null
  reserve21 = null
  reserve22 = null
  reserve23 = null
  reserve24 = null
  reserve25 = null
  reserve26 = null
  reserve27 = null
  reserve28 = null
  reserve29 = null
  reserve30 = null
  reserve31 = null
  reserve32 = null
  reserve33 = null
  reserve34 = null
  reserve35 = null
  reserve36 = null
  reserve37 = null
  reserve38 = null
  reserve39 = null
  reserve40 = null
  reserve41 = null
  reserve42 = null
  reserve43 = null
  reserve44 = null
  reserve45 = null
  reserve46 = null
  reserve47 = null
  reserve48 = null
  reserve49 = null
  reserve50 = null
  reserve51 = null
  reserve52 = null
  reserve53 = null
  reserve54 = null
  reserve55 = null
  reserve56 = null
  reserve57 = null
  reserve58 = null
  reserve59 = null
  reserve60 = null
  reserve61 = null
  reserve62 = null
  reserve63 = null
  reserve64 = null
  reserve65 = null
  reserve66 = null
  reserve67 = null
  reserve68 = null
  reserve69 = null
  reserve70 = null
  reserve71 = null
  reserve72 = null
  reserve73 = null
  reserve74 = null
  reserve75 = null
  reserve76 = null
  reserve77 = null
  reserve78 = null
  reserve79 = null
  reserve80 = null
  reserve81 = null
  reserve82 = null
  reserve83 = null
  reserve84 = null
  reserve85 = null
  reserve86 = null
  reserve87 = null
  reserve88 = null
  reserve89 = null
  reserve90 = null
  reserve91 = null
  reserve92 = null
  reserve93 = null
  reserve94 = null
  reserve95 = null
  reserve96 = null
  reserve97 = null
  reserve98 = null
  reserve99 = null
  reserve100 = null
  reserve101 = null
  reserve102 = null
  reserve103 = null
  reserve104 = null
  reserve105 = null
  reserve106 = null
  reserve107 = null
  reserve108 = null
  reserve109 = null
  reserve110 = null
  reserve111 = null
  reserve112 = null
  reserve113 = null
  reserve114 = null
  reserve115 = null
  reserve116 = null
  reserve117 = null
  reserve118 = null
  reserve119 = null
  reserve120 = null
  reserve121 = null
  reserve122 = null
  reserve123 = null
  reserve124 = null
  reserve125 = null
  reserve126 = null
  reserve127 = null
  reserve128 = null
  reserve129 = null
  reserve130 = null
  reserve131 = null
  reserve132 = null
  reserve133 = null
  reserve134 = null
  reserve135 = null
  reserve136 = null
  reserve137 = null
  reserve138 = null
  reserve139 = null
  reserve140 = null
  deleted = '0'
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = 0
  itemPatitionFlag = null
  receiveOrderDetails = []
  processStatus = PROCESS_STATUS.DONE // 0：完了
  //#2785
  //運賃計算対象外フラグ
  freightCalcExcludeFlag = 0 // 0：初期値
}
