import {
  openMsgModal,
  openSubModal,
  downloadCSV,
  formatYYYYMMDD,
  displaYyyymmdd,
  displayHHmmss,
  //displayYYYYYMMDDHHmm,
  csvFileName,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  TABLE_OPTION,
  PLANNING_STATUS,
  USER_EROLE,
} from '@/common/const.js'
import router from '@/router'
import ReceiveOrderService from './ReceiveOrderService'

const screenId = SCREEN_ID.RECEIVE_ORDER_LIST
const detailScreenId = SCREEN_ID.RECEIVE_ORDER_DETAIL

export function init(obj) {
  setUserDepartmentInfo(obj)

  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.searchCondition.periodDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateFrom
  )
  obj.searchCondition.periodDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateTo
  )

  obj.$store.dispatch('init/setServiceLoading')
  ReceiveOrderService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList.map((element) => {
          if (element.canceled === '1') {
            element._rowVariant = TABLE_OPTION.DELETED_ROW_VARIANT
          }
          return element
        })

        obj.tableItems.forEach((element) => {
          //受信日時
          element.receive_date = displaYyyymmdd(obj, element.receive_date)
          //出荷開始日
          element.departure_begin_date = displaYyyymmdd(
            obj,
            element.departure_begin_date
          )
          //出荷開始時刻 int->string
          console.log('1 departure_begin_time=' + element.departure_begin_time)
          element.departure_begin_time = displayHHmmss(
            element.departure_begin_time
          )
          console.log('2 departure_begin_time=' + element.departure_begin_time)
          //出荷終了日
          element.departure_end_date = displaYyyymmdd(
            obj,
            element.departure_end_date
          )
          //出荷終了時刻
          element.departure_begin_time = displayHHmmss(
            element.departure_begin_time
          )
          // //出荷予定日時
          // element.departure_date_time = displayYYYYYMMDDHHmm(
          //   obj,
          //   element.departure_date_time
          // )
          //搬入開始日
          element.arrival_begin_date = displaYyyymmdd(
            obj,
            element.arrival_begin_date
          )
          //搬入開始時刻
          element.arrival_begin_time = displayHHmmss(element.arrival_begin_time)
          //搬入終了日
          element.arrival_end_date = displaYyyymmdd(
            obj,
            element.arrival_end_date
          )
          //搬入予定日時
          element.arrival_end_time = displayHHmmss(element.arrival_end_time)
          //搬入予定日時
          // element.arrival_date_time = displayYYYYYMMDDHHmm(
          //   obj,
          //   element.arrival_date_time
          // )

          element.period_date_from = displaYyyymmdd(
            obj,
            element.period_date_from
          )
          element.period_date_to = displaYyyymmdd(obj, element.period_date_to)
        })
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()

  setUserDepartmentInfo(obj)

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        orderId: obj.selectedItem[0].order_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「新規」ボタンクリック
export function goNew() {
  router.push({
    name: detailScreenId,
    params: {
      screenKbn: DETAIL_SCREEN_KBN.NEW,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: screenId,
          params: { isReSearch: true },
        },
      },
    },
  })
}

// 「コピー」ボタンクリック
export function goCopy(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        orderId: obj.selectedItem[0].order_id,
        screenKbn: DETAIL_SCREEN_KBN.COPY,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「削除（取消）」ボタンクリック
export function goRemove(obj) {
  // 配車ステータスが"未割付"かチェックする。

  //{0}処理を行います。よろしいですか？
  let msgId = 'YZ00MG994Q'
  let isNotPlanningStatus = false

  obj.selectedItem.forEach((element) => {
    if (element.planning_status != PLANNING_STATUS.NOT_ASSIGNED) {
      isNotPlanningStatus = true
      return
    }
  })

  if (isNotPlanningStatus == true) {
    // 割付済オーダーですが、{0}処理してよろしいですか？
    msgId = 'EA01S001_MG006Q'
  }

  obj.removeMessage = obj.$store.getters['init/getMessage'](
    msgId,
    obj.resultControlInfo.delete.label
  )

  openSubModal(obj, 'remove-modal')
}

// 「削除」ボタンクリック
export function goEdit(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.delete.label
  )
  openSubModal(obj, 'edit-modal')
}

/**
 * 選択した作業データを削除します.
 */
export async function remove(obj) {
  try {
    obj.$bvModal.hide('remove-modal')

    let isError = false
    let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      var orderId = obj.selectedItem[index].order_id
      obj.$store.dispatch('init/setServiceLoading')
      await ReceiveOrderService.get(orderId)
        .then(
          (response) => {
            removeList.push(response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
    if (isError == false && removeList.length == obj.selectedItem.length) {
      obj.$store.dispatch('init/setServiceLoading')
      await ReceiveOrderService.removeList(removeList)
        .then(
          () => {
            // 警告モーダル表示
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage'](
                'YZ00MG995I',
                obj.resultControlInfo.delete.label
              )
            )
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

// 「CSV出力」ボタンクリック
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  obj.searchCondition.periodDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateFrom
  )
  obj.searchCondition.periodDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateTo
  )

  //obj.searchCondition.filename = 'EA01F001.csv'
  let newCsvName = csvFileName(obj, screenId)
  obj.searchCondition.filename = newCsvName

  obj.$store.dispatch('init/setServiceLoading')
  ReceiveOrderService.download(obj.searchCondition)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(obj.searchCondition.filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 「CSV取込」ボタンクリック
export function upload(obj, file) {
  if (file == null || file.name.includes('.csv') == false) {
    // csvファイルを選択してください
    openMsgModal(
      obj,
      obj.MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG936E')
    )
    return
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'csv')

  obj.$store.dispatch('init/setServiceLoading')
  ReceiveOrderService.upload(formData)
    .then(
      () => {
        obj.$bvModal.hide('upload-modal')
        openMsgModal(
          obj,
          obj.MSG_TYPE.I,
          obj.$store.getters['init/getMessage']('YZ00MG958I')
        )
        reSearch(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function setUserDepartmentInfo(obj) {
  let loginUserRoleId = obj.$store.getters['authentication/getRoleId']
  if (loginUserRoleId == USER_EROLE.OWNER) {
    obj.searchCondition.ownerCode =
      obj.$store.getters['authentication/getUserDepartmentCode']
    obj.searchCondition.ownerName =
      obj.$store.getters['authentication/getUserDepartmentName']
  }
}
