import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  formatHHmmss,
  displaYyyymmdd,
  displayHHmmss,
  convertSnakeCace,
  convertCamelCace,
  getMasterName,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  PROCESS_KIND,
  VISIBILITY,
  CANCEL_FLAG,
  AUTO_PLANNING_EXCLUDE_FLAG,
  HIGHWAY_USE_YN_FLAG,
  GO_OR_BACK,
  IS_MULTIPLE_LOADING,
  MASTER_CODE,
  PROCESS_STATUS,
  IS_SIMULATION_DATA,
  VEHICLE_CONDITION,
  USER_EROLE,
  LOCK_FLAG,
} from '@/common/const.js'
import ReceiveOrderService from './ReceiveOrderService'
import ProductPatternService from '@/master/productPattern/ProductPatternService'
import ProductService from '@/master/product/ProductService'
import _ from 'lodash'

export function init(obj) {
  //init disable
  setInitDisplayScreenItemsDisable(obj)

  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    ReceiveOrderService.get(obj.orderId)
      .then(
        (response) => {
          obj.formData = response.data

          //YYYY/MM/DD表示
          //HH:mm:ss表示
          obj.formData.receiveOrderDetails.map(function (x) {
            //date, time
            x.departureBeginDate = displaYyyymmdd(obj, x.departureBeginDate)
            x.departureEndDate = displaYyyymmdd(obj, x.departureEndDate)
            x.departureBeginTime = displayHHmmss(x.departureBeginTime)
            x.departureEndTime = displayHHmmss(x.departureEndTime)

            x.arrivalBeginDate = displaYyyymmdd(obj, x.arrivalBeginDate)
            x.arrivalEndDate = displaYyyymmdd(obj, x.arrivalEndDate)
            x.arrivalBeginTime = displayHHmmss(x.arrivalBeginTime)
            x.arrivalEndTime = displayHHmmss(x.arrivalEndTime)

            x.preloadDate = displaYyyymmdd(obj, x.preloadDate)
            x.preloadBeginTime = displayHHmmss(x.preloadBeginTime)
          })

          // details
          var details = convertSnakeCace(obj.formData.receiveOrderDetails)
          details.sort(function (a, b) {
            return a.order_id - b.order_id
          })

          obj.formData.receiveOrderDetails = details
          var initNum = Number(obj.formData.totalQuantity)
          var initWeight = Number(obj.formData.totalWeight)
          var initVolume = Number(obj.formData.totalVolume)
          if (initNum > 0) {
            _.map(obj.formData.receiveOrderDetails, function (x) {
              x.weight_zero = initWeight / initNum
              x.volume_zero = initVolume / initNum
              return x
            })
          } else {
            _.map(obj.formData.receiveOrderDetails, function (x) {
              x.weight_zero = 1
              x.volume_zero = 1
              return x
            })
          }
          displayTotalValue(obj)

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.canceled = CANCEL_FLAG.NORMAL
            obj.formData.orderId = null
            obj.formData.receptProcess = 'NEUPLANET'
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null

            _.map(obj.formData.receiveOrderDetails, function (x) {
              x.process_kind = PROCESS_KIND.UPDATE
              x.order_detail_id = null //受注オーダ明細ID
              x.order_id = null //受注オーダID
              x.registration_time = null
              x.registration_time_display = null
              x.registration_user = null
              x.update_time = null
              x.update_time_display = null
              x.update_user = null
              return x
            })
          } else {
            obj.formData.orderId = obj.orderId
          }
          obj.tableItems = obj.formData.receiveOrderDetails
          obj.oriData = _.cloneDeep(obj.formData)
          // 削除用情報格納
          obj.oriTableItems = []

          // detailitems - display disable
          setDisplayScreenItemsDisable(obj)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message + e.response.data.messageDetail
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    //DETAIL_SCREEN_KBN.NEW
    let loginUserBlockCode = obj.$store.getters['authentication/getBlockCode']
    obj.formData.blockCode = loginUserBlockCode
    obj.formData.receiveOrderDetails = []
    obj.formData.receptProcess = 'NEUPLANET'
  }
}

export function tableRowEdit(obj) {
  // 情報モーダル表示
  obj.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
  obj.selectedReceiveOrderDetail.push(obj)
  openSubModal(obj, 'receive-order-detail-edit')
}

export function tableRowAdd(obj) {
  // 情報モーダル表示
  obj.detailScreenKbn = DETAIL_SCREEN_KBN.NEW
  obj.selectedReceiveOrderDetail.push(obj)
  openSubModal(obj, 'receive-order-detail-edit')
}

export function tableRowProductBatch(obj) {
  openSubModal(obj, 'product-pattern-modal')
}

/**
 * 荷姿一括追加
 * @param {*} obj
 * @param {*} productPatternInfos
 */
export function setProductPatternInfos(obj, productPatternInfos) {
  if (productPatternInfos.length > 0) {
    productPatternInfos.forEach((productPatternInfo) => {
      getProductInfos(obj, productPatternInfo.product_pattern_id)
    })
  }
  //「総数量」「総質量」「総容量」再計算
  displayTotalValue(obj)
}

export function setOrderDetail(obj, info) {
  if (obj.detailScreenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.formData.receiveOrderDetails[obj.detailRowIndex].product_id =
      info.productId
    obj.formData.receiveOrderDetails[obj.detailRowIndex].product_code =
      info.productCode
    obj.formData.receiveOrderDetails[obj.detailRowIndex].product_name =
      info.productName
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].auto_section_divisions = info.autoSectionDivisions // 自動区間分割可否
    obj.formData.receiveOrderDetails[obj.detailRowIndex].tariff_product_code =
      info.tariffProductCode

    obj.formData.receiveOrderDetails[obj.detailRowIndex].quantity =
      info.quantity
    obj.formData.receiveOrderDetails[obj.detailRowIndex].weight = info.weight
    obj.formData.receiveOrderDetails[obj.detailRowIndex].volume = info.volume
    obj.formData.receiveOrderDetails[obj.detailRowIndex].length = info.length
    obj.formData.receiveOrderDetails[obj.detailRowIndex].width = info.width
    obj.formData.receiveOrderDetails[obj.detailRowIndex].height = info.height

    obj.formData.receiveOrderDetails[obj.detailRowIndex].note = info.note
    obj.formData.receiveOrderDetails[obj.detailRowIndex].warning_message =
      info.warningMessage

    //Edit
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].departure_begin_date = displaYyyymmdd(obj, info.departureBeginDateEdit)
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].departure_begin_time = displayHHmmss(info.departureBeginTimeEdit)
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].departure_end_date = displaYyyymmdd(obj, info.departureEndDateEdit)
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].departure_end_time = displayHHmmss(info.departureEndTimeEdit)

    obj.formData.receiveOrderDetails[obj.detailRowIndex].departure_place_id =
      info.departurePlaceId
    obj.formData.receiveOrderDetails[obj.detailRowIndex].departure_place_code =
      info.departurePlaceCode
    obj.formData.receiveOrderDetails[obj.detailRowIndex].departure_place_name =
      info.departurePlaceName
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].departure_place_assign_flag = info.departurePlaceAssignFlag
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].departure_time_assign_kind = info.departureTimeAssignKind
    obj.formData.receiveOrderDetails[obj.detailRowIndex].departure_time_strict =
      info.departureTimeStrict

    //Edit
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].arrival_begin_date = displaYyyymmdd(obj, info.arrivalBeginDateEdit)
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].arrival_begin_time = displayHHmmss(info.arrivalBeginTimeEdit)
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].arrival_end_date = displaYyyymmdd(obj, info.arrivalEndDateEdit)
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].arrival_end_time = displayHHmmss(info.arrivalEndTimeEdit)

    obj.formData.receiveOrderDetails[obj.detailRowIndex].arrival_place_id =
      info.arrivalPlaceId
    obj.formData.receiveOrderDetails[obj.detailRowIndex].arrival_place_code =
      info.arrivalPlaceCode
    obj.formData.receiveOrderDetails[obj.detailRowIndex].arrival_place_name =
      info.arrivalPlaceName
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].arrival_time_assign_kind = info.arrivalTimeAssignKind
    obj.formData.receiveOrderDetails[obj.detailRowIndex].arrival_time_strict =
      info.arrivalTimeStrict
    obj.formData.receiveOrderDetails[obj.detailRowIndex].departure_condition =
      info.departureCondition
    obj.formData.receiveOrderDetails[obj.detailRowIndex].arrival_condition =
      info.arrivalCondition
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].auto_planning_exclude_flag = info.autoPlanningExcludeFlag

    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].preload_date = displaYyyymmdd(obj, info.preloadDate)
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].preload_begin_time = displayHHmmss(info.preloadBeginTime)

    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_trip_no =
      info.assignTripNo
    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_drop_no =
      info.assignDropNo
    // obj.formData.receiveOrderDetails[obj.detailRowIndex].order_kind =
    //     info.orderKind
    // obj.formData.receiveOrderDetails[
    //   obj.detailRowIndex
    // ].preloadAssignDate = formatYYYYMMDD(info.preload_assign_date)
    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_vehicle_id =
      info.assignVehicleId
    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_vehicle_code =
      info.assignVehicleCode
    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_vehicle_name =
      info.assignVehicleName
    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_carrier_id =
      info.assignCarrierId
    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_carrier_code =
      info.assignCarrierCode
    obj.formData.receiveOrderDetails[obj.detailRowIndex].assign_carrier_name =
      info.assignCarrierName
    obj.formData.receiveOrderDetails[obj.detailRowIndex].preload_kind =
      info.preloadKind

    obj.formData.receiveOrderDetails[obj.detailRowIndex].canceled =
      info.canceled
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_category =
      info.vehicleCategory

    obj.formData.receiveOrderDetails[obj.detailRowIndex].highway_use_yn_flag =
      info.highwayUseYnFlag
    obj.formData.receiveOrderDetails[obj.detailRowIndex].order_distance =
      info.orderDistance
    obj.formData.receiveOrderDetails[obj.detailRowIndex].oneway_or_round =
      info.onewayOrRound
    obj.formData.receiveOrderDetails[obj.detailRowIndex].is_multiple_loading =
      info.isMultipleLoading

    // obj.formData.receiveOrderDetails[obj.detailRowIndex].process_status =
    //  PROCESS_STATUS.DONE // 0：完了
    obj.formData.receiveOrderDetails[obj.detailRowIndex].is_simulation_data =
      info.isSimulationData
    obj.formData.receiveOrderDetails[
      obj.detailRowIndex
    ].is_simulation_data_name = info.isSimulationDataName
    obj.formData.receiveOrderDetails[obj.detailRowIndex].process_status =
      info.processStatus
    obj.formData.receiveOrderDetails[obj.detailRowIndex].process_status_name =
      info.processStatusName

    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition01 =
      info.vehicleCondition01
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition02 =
      info.vehicleCondition02
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition03 =
      info.vehicleCondition03
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition04 =
      info.vehicleCondition04
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition05 =
      info.vehicleCondition05
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition06 =
      info.vehicleCondition06
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition07 =
      info.vehicleCondition07
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition08 =
      info.vehicleCondition08
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition09 =
      info.vehicleCondition09
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition10 =
      info.vehicleCondition10
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition11 =
      info.vehicleCondition11
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition12 =
      info.vehicleCondition12
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition13 =
      info.vehicleCondition13
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition14 =
      info.vehicleCondition14
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition15 =
      info.vehicleCondition15
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition16 =
      info.vehicleCondition16
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition17 =
      info.vehicleCondition17
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition18 =
      info.vehicleCondition18
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition19 =
      info.vehicleCondition19
    obj.formData.receiveOrderDetails[obj.detailRowIndex].vehicle_condition20 =
      info.vehicleCondition20
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve01 =
      info.reserve01
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve02 =
      info.reserve02
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve03 =
      info.reserve03
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve04 =
      info.reserve04
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve05 =
      info.reserve05
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve06 =
      info.reserve06
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve07 =
      info.reserve07
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve08 =
      info.reserve08
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve09 =
      info.reserve09
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve10 =
      info.reserve10
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve11 =
      info.reserve11
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve12 =
      info.reserve12
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve13 =
      info.reserve13
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve14 =
      info.reserve14
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve15 =
      info.reserve15
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve16 =
      info.reserve16
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve17 =
      info.reserve17
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve18 =
      info.reserve18
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve19 =
      info.reserve19
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve20 =
      info.reserve20
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve21 =
      info.reserve21
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve22 =
      info.reserve22
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve23 =
      info.reserve23
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve24 =
      info.reserve24
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve25 =
      info.reserve25
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve26 =
      info.reserve26
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve27 =
      info.reserve27
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve28 =
      info.reserve28
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve29 =
      info.reserve29
    obj.formData.receiveOrderDetails[obj.detailRowIndex].reserve30 =
      info.reserve30
  } else {
    //追加
    setItemInfo(obj, info)
  }
  displayTotalValue(obj)
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  console.log(obj.formData)
  if (obj.formData.receiveOrderDetails.length === 0) {
    // 明細を追加してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MH01S002_MG001E')
      //   '一覧にデータが存在しません。' // obj.$store.getters['init/getMessage']('EA01S002_MG013E')
    )
    return
  }

  if (obj.formData.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  } else {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.register.label
    )
  }
  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let newFormData = convertFormDataToReceiveOrderDto(obj)

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = ReceiveOrderService.create(newFormData)
  } else {
    // 詳細
    callApi = ReceiveOrderService.update(obj.orderId, newFormData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function convertFormDataToReceiveOrderDto(obj) {
  let newFormData = _.cloneDeep(obj.formData)

  //-----------------------------------
  // 受注オーダー情報
  //-----------------------------------
  newFormData.departureBeginDate = formatYYYYMMDD(
    obj,
    newFormData.departureBeginDate
  )
  newFormData.departureEndDate = formatYYYYMMDD(
    obj,
    newFormData.departureEndDate
  )
  newFormData.arrivalBeginDate = formatYYYYMMDD(
    obj,
    newFormData.arrivalBeginDate
  )
  newFormData.arrivalEndDate = formatYYYYMMDD(obj, newFormData.arrivalEndDate)

  newFormData.departureBeginTime = formatHHmmss(newFormData.departureBeginTime)
  newFormData.departureEndTime = formatHHmmss(newFormData.departureEndTime)
  newFormData.arrivalBeginTime = formatHHmmss(newFormData.arrivalBeginTime)
  newFormData.arrivalEndTime = formatHHmmss(newFormData.arrivalEndTime)

  // // 画面には「終了日付」はないため、「開始日付」を設定する。
  // newFormData.departureEndDate = newFormData.departureBeginDate
  // newFormData.arrivalEndDate = newFormData.arrivalBeginDate

  //-----------------------------------
  // 明細情報
  //-----------------------------------
  // 画面表示情報＋削除情報
  newFormData.receiveOrderDetails = mergeReceiveOrderDetails(
    obj.oriTableItems,
    newFormData.receiveOrderDetails
  )

  newFormData.receiveOrderDetails = convertCamelCace(
    newFormData.receiveOrderDetails
  )

  //YYYYMMDD再設定
  //HHmmss再設定
  newFormData.receiveOrderDetails.map(function (x) {
    //date, time
    x.departureBeginDate = formatYYYYMMDD(obj, x.departureBeginDate)
    x.departureEndDate = formatYYYYMMDD(obj, x.departureEndDate)
    x.departureBeginTime = formatHHmmss(x.departureBeginTime)
    x.departureEndTime = formatHHmmss(x.departureEndTime)

    x.arrivalBeginDate = formatYYYYMMDD(obj, x.arrivalBeginDate)
    x.arrivalEndDate = formatYYYYMMDD(obj, x.arrivalEndDate)
    x.arrivalBeginTime = formatHHmmss(x.arrivalBeginTime)
    x.arrivalEndTime = formatHHmmss(x.arrivalEndTime)

    x.preloadDate = formatYYYYMMDD(obj, x.preloadDate)
    x.preloadBeginTime = formatHHmmss(x.preloadBeginTime)
  })
  return newFormData
}

function mergeReceiveOrderDetails(deleteItems, tableItems) {
  if (deleteItems.length > 0) {
    // 画面表示情報＋削除情報
    for (let info of deleteItems) {
      console.log('画面表示情報＋削除情報')
      tableItems.push(_.cloneDeep(info))
    }
  }
  return tableItems
}

/**
 * 行削除
 * @param {*} obj
 */
export function tableRowRemove(obj) {
  // 複数個削除
  for (let info of obj.selectedItem) {
    const index = obj.formData.receiveOrderDetails.indexOf(info)
    if (info.order_detail_id != null) {
      // DB登録されている行に対して「削除」ボタン押下した場合
      obj.oriTableItems.push(_.cloneDeep(info))
      let oriIndex = obj.oriTableItems.length - 1
      obj.oriTableItems[oriIndex].deleted = 1
      obj.oriTableItems[oriIndex].process_kind = PROCESS_KIND.DELETE
      obj.formData.receiveOrderDetails.splice(index, 1)
    } else if (info.order_detail_id == null) {
      // 「新規登録」ボタンから追加した行に対して「削除」ボタン押下した場合
      obj.formData.receiveOrderDetails.splice(index, 1)
    }
    obj.tableItems = obj.formData.receiveOrderDetails
  }
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.tableItems = obj.formData.receiveOrderDetails || []
  // 削除用情報格納
  obj.oriTableItems = []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

// 「削除」ボタンクリック
export function goAllRemove(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.delete.label
  )
  openSubModal(obj, 'remove-modal')
}

// 「削除」ボタンクリック
export async function remove(obj) {
  obj.$bvModal.hide('remove-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let newFormData = convertFormDataToReceiveOrderDto(obj)

  var callApi
  if (obj.screenKbn == DETAIL_SCREEN_KBN.DETAIL) {
    callApi = ReceiveOrderService.deleteLogical(obj.orderId, newFormData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 「販売情報」ボタンクリック
export function goSaleInfo(obj) {
  obj.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
  let modalCondition = {
    orderId: obj.orderId,
  }
  obj.saleModalCondition = modalCondition
  openSubModal(obj, 'sale-modal')
}

/**
 * 「総数量」「総質量」「総容量」計算
 */
export function displayTotalValue(obj) {
  var totalNum = 0
  var totalWeight = 0
  var totalVol = 0
  var msg = ''
  for (
    let index = 0;
    index < obj.formData.receiveOrderDetails.length;
    index++
  ) {
    // 処理区分＝削除の場合、合計算出しない。
    if (obj.formData.receiveOrderDetails[index].deleted == '0') {
      // 「数量」「質量」「容量」に不正な値が入力されていた場合にメッセージを表示しつつ値をクリアするように修正
      try {
        var iQuantity = Number(obj.formData.receiveOrderDetails[index].quantity)
      } catch {
        //数量に不正な値が設定されたためクリアします。
        msg = obj.$store.getters['init/getMessage']('EA01S002_MG001E')
        openMsgModal(this, MSG_TYPE.E, msg)
        return 'error'
      }
      try {
        var iWeight = Number(obj.formData.receiveOrderDetails[index].weight)
      } catch {
        //質量に不正な値が設定されたためクリアします。
        msg = obj.$store.getters['init/getMessage']('EA01S002_MG002E')
        openMsgModal(this, MSG_TYPE.E, msg)
        obj.formData.receiveOrderDetails[index].weight = 0
        return 'error'
      }
      try {
        var iVolume = Number(obj.formData.receiveOrderDetails[index].volume)
      } catch {
        //容量に不正な値が設定されたためクリアします。
        msg = obj.$store.getters['init/getMessage']('EA01S002_MG003E')
        openMsgModal(this, MSG_TYPE.E, msg)
        obj.formData.receiveOrderDetails[index].volume = 0
        return 'error'
      }
      totalNum += iQuantity
      totalWeight += iWeight
      totalVol += iVolume
    }
  }

  // 合計値の再設定
  obj.formData.totalQuantity = totalNum
  obj.formData.totalWeight = totalWeight
  obj.formData.totalVolume = totalVol
}

function setItemInfo(obj, itemInfo) {
  if (
    !obj.formData.receiveOrderDetails.some(
      (list) => list.product_id === itemInfo.productId
    )
  ) {
    let addInfo = {
      process_kind: PROCESS_KIND.INSERT, //登録
      order_detail_id: null,
      product_id: itemInfo.productId,
      product_code: itemInfo.productCode,
      product_name: itemInfo.productName,
      auto_section_divisions: itemInfo.autoSectionDivisions, // 自動区間分割可否
      tariff_product_code: itemInfo.tariffProductCode,
      quantity: itemInfo.quantity,
      weight: itemInfo.weight,
      volume: itemInfo.volume,
      length: itemInfo.length,
      width: itemInfo.width,
      height: itemInfo.height,
      warning_message: itemInfo.warningMessage,
      note: itemInfo.note,

      //Edit
      departure_begin_date: displaYyyymmdd(
        obj,
        itemInfo.departureBeginDateEdit
      ),
      departure_begin_time: displayHHmmss(itemInfo.departureBeginTimeEdit),
      departure_end_date: displaYyyymmdd(obj, itemInfo.departureEndDateEdit),
      departure_end_time: displayHHmmss(itemInfo.departureEndTimeEdit),

      departure_place_id: itemInfo.departurePlaceId,
      departure_place_code: itemInfo.departurePlaceCode,
      departure_place_name: itemInfo.departurePlaceName,
      departure_place_assign_flag: itemInfo.departurePlaceAssignFlag,
      departure_time_assign_kind: itemInfo.departureTimeAssignKind,
      departure_time_strict: itemInfo.departureTimeStrict,

      // Edit
      arrival_begin_date: displaYyyymmdd(obj, itemInfo.arrivalBeginDateEdit),
      arrival_begin_time: displayHHmmss(itemInfo.arrivalBeginTimeEdit),
      arrival_end_date: displaYyyymmdd(obj, itemInfo.arrivalEndDateEdit),
      arrival_end_time: displayHHmmss(itemInfo.arrivalEndTimeEdit),

      arrival_place_id: itemInfo.arrivalPlaceId,
      arrival_place_code: itemInfo.arrivalPlaceCode,
      arrival_place_name: itemInfo.arrivalPlaceName,
      arrival_time_assign_kind: itemInfo.arrivalTimeAssignKind,
      arrival_time_strict: itemInfo.arrivalTimeStrict,
      departure_condition: itemInfo.departureCondition,
      arrival_condition: itemInfo.arrivalCondition,
      auto_planning_exclude_flag: itemInfo.autoPlanningExcludeFlag,
      preload_kind: itemInfo.preloadKind,

      preload_date: displaYyyymmdd(obj, itemInfo.preloadDate),
      preload_begin_time: displayHHmmss(itemInfo.preloadBeginTime),
      assign_trip_no: itemInfo.assignTripNo,
      assign_drop_no: itemInfo.assignDropNo,
      assign_vehicle_id: itemInfo.assignVehicleId,
      assign_vehicle_name: itemInfo.assignVehicleName,
      assign_vehicle_code: itemInfo.assignVehicleCode,
      assign_carrier_id: itemInfo.assignCarrivehicle_conditionerId,
      assign_carrier_code: itemInfo.assignCarrierCode,
      assign_carrier_name: itemInfo.assignCarrierName,
      canceled: itemInfo.canceled,
      vehicle_category: itemInfo.vehicleCategory,

      highway_use_yn_flag: itemInfo.highwayUseYnFlag,
      highway_use_yn_flag_name: itemInfo.highwayUseYnFlagName,
      order_distance: itemInfo.orderDistance,
      oneway_or_round: itemInfo.onewayOrRound,
      oneway_or_round_name: itemInfo.onewayOrRoundName,
      is_multiple_loading: itemInfo.isMultipleLoading,
      is_multiple_loading_name: itemInfo.isMultipleLoadingName,

      vehicle_condition01: itemInfo.vehicleCondition01,
      vehicle_condition02: itemInfo.vehicleCondition02,
      vehicle_condition03: itemInfo.vehicleCondition03,
      vehicle_condition04: itemInfo.vehicleCondition04,
      vehicle_condition05: itemInfo.vehicleCondition05,
      vehicle_condition06: itemInfo.vehicleCondition06,
      vehicle_condition07: itemInfo.vehicleCondition07,
      vehicle_condition08: itemInfo.vehicleCondition08,
      vehicle_condition09: itemInfo.vehicleCondition09,
      vehicle_condition10: itemInfo.vehicleCondition10,
      vehicle_condition11: itemInfo.vehicleCondition11,
      vehicle_condition12: itemInfo.vehicleCondition12,
      vehicle_condition13: itemInfo.vehicleCondition13,
      vehicle_condition14: itemInfo.vehicleCondition14,
      vehicle_condition15: itemInfo.vehicleCondition15,
      vehicle_condition16: itemInfo.vehicleCondition16,
      vehicle_condition17: itemInfo.vehicleCondition17,
      vehicle_condition18: itemInfo.vehicleCondition18,
      vehicle_condition19: itemInfo.vehicleCondition19,
      vehicle_condition20: itemInfo.vehicleCondition20,
      reserve01: itemInfo.reserve01,
      reserve02: itemInfo.reserve02,
      reserve03: itemInfo.reserve03,
      reserve04: itemInfo.reserve04,
      reserve05: itemInfo.reserve05,
      reserve06: itemInfo.reserve06,
      reserve07: itemInfo.reserve07,
      reserve08: itemInfo.reserve08,
      reserve09: itemInfo.reserve09,
      reserve10: itemInfo.reserve10,
      reserve11: itemInfo.reserve11,
      reserve12: itemInfo.reserve12,
      reserve13: itemInfo.reserve13,
      reserve14: itemInfo.reserve14,
      reserve15: itemInfo.reserve15,
      reserve16: itemInfo.reserve16,
      reserve17: itemInfo.reserve17,
      reserve18: itemInfo.reserve18,
      reserve19: itemInfo.reserve19,
      reserve20: itemInfo.reserve20,
      reserve21: itemInfo.reserve21,
      reserve22: itemInfo.reserve22,
      reserve23: itemInfo.reserve23,
      reserve24: itemInfo.reserve24,
      reserve25: itemInfo.reserve25,
      reserve26: itemInfo.reserve26,
      reserve27: itemInfo.reserve27,
      reserve28: itemInfo.reserve28,
      reserve29: itemInfo.reserve29,
      reserve30: itemInfo.reserve30,

      is_simulation_data: itemInfo.isSimulationData,
      is_simulation_data_name: null,
      process_status: PROCESS_STATUS.DONE, // 0：完了
      process_status_name: null, // 0：完了
      lock_flag: LOCK_FLAG.NORMAL, // 0：通常
      lock_flag_name: getMasterName(MASTER_CODE.LOCK_FLAG, LOCK_FLAG.NORMAL),
      registration_time: null,
      registration_user: null,
      update_by_trigger: null,
      update_time: null,
      update_user: null,
      version: null,
      deleted: 0,
    }
    obj.formData.receiveOrderDetails.push(addInfo)
  }
  obj.tableItems = obj.formData.receiveOrderDetails
}

/**
 * 場所マスタから取得データ設定
 */
export function setPlaceInfo(obj, selectedItem) {
  if (obj.placeModalKbn == 1) {
    //出荷
    obj.formData.departurePlaceId = selectedItem.place_id
    obj.formData.departurePlaceCode = selectedItem.place_code
    obj.formData.departurePlaceName = selectedItem.place_name

    // if (selectedItem.postal_code == null) {
    //   obj.formData.departurePostalCode = ''
    // } else {
    //   obj.formData.departurePostalCode = selectedItem.postal_code
    // }
    // if (selectedItem.prefecture_name == null) {
    //   obj.formData.departureAddress1 = ''
    // } else {
    //   obj.formData.departureAddress1 = selectedItem.prefecture_name
    //     .concat('')
    //     .concat(selectedItem.city_name)
    // }
    // if (selectedItem.address1 == null) {
    //   obj.formData.departureAddress2 = ''
    // } else {
    //   obj.formData.departureAddress2 = selectedItem.address1
    //     .concat('')
    //     .concat(selectedItem.address2)
    // }
    obj.formData.departurePostalCode = selectedItem.postal_code
    obj.formData.departureAddress1 = selectedItem.address1
    obj.formData.departureAddress2 = selectedItem.address2

    obj.formData.departureRepresentativePlaceName =
      selectedItem.representative_place_name
    //最寄りIC
    obj.formData.departureIcPlaceName = selectedItem.nearest_highway_ic_name
  } else {
    //搬入
    obj.formData.arrivalPlaceId = selectedItem.place_id
    obj.formData.arrivalPlaceCode = selectedItem.place_code
    obj.formData.arrivalPlaceName = selectedItem.place_name

    // if (selectedItem.postal_code == null) {
    //   obj.formData.arrivalPostalCode = ''
    // } else {
    //   obj.formData.arrivalPostalCode = selectedItem.postal_code
    // }
    // if (selectedItem.prefecture_name == null) {
    //   obj.formData.arrivalAddress1 = ''
    // } else {
    //   obj.formData.arrivalAddress1 = selectedItem.prefecture_name
    //     .concat('')
    //     .concat(selectedItem.city_name)
    // }
    // if (selectedItem.address1 == null) {
    //   obj.formData.arrivalAddress2 = ''
    // } else {
    //   obj.formData.arrivalAddress2 = selectedItem.address1
    //     .concat('')
    //     .concat(selectedItem.address2)
    // }
    obj.formData.arrivalPostalCode = selectedItem.postal_code
    obj.formData.arrivalAddress1 = selectedItem.address1
    obj.formData.arrivalAddress2 = selectedItem.address2

    obj.formData.arrivalRepresentativePlaceName =
      selectedItem.representative_place_name
    //最寄りIC
    obj.formData.arrivalIcPlaceName = selectedItem.nearest_highway_ic_name
  }
}

/**
 * 場所情報解除
 */
export function unsetPlaceInfo(obj) {
  if (obj.placeModalKbn == 1) {
    //出荷
    obj.formData.departurePlaceId = null
    obj.formData.departurePlaceCode = ''
    obj.formData.departurePlaceName = ''

    if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
      obj.formData.departurePostalCode = ''
      obj.formData.departureAddress1 = ''
      obj.formData.departureAddress2 = ''
    }
    //最寄りIC
    obj.formData.departureIcPlaceName = ''
  } else {
    //搬入
    obj.formData.arrivalPlaceId = null
    obj.formData.arrivalPlaceCode = ''
    obj.formData.arrivalPlaceName = ''

    if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
      obj.formData.arrivalPostalCode = ''
      obj.formData.arrivalAddress1 = ''
      obj.formData.arrivalAddress2 = ''
    }
    //最寄りIC
    obj.formData.arrivalIcPlaceName = ''
  }
}

/**
 * 画面項目のInit Disable属性設定
 */
function setInitDisplayScreenItemsDisable(obj) {
  // readonly item
  // block
  //obj.detailInfo.block_name.visibility = VISIBILITY.DISABLE

  // 場所名(代表)
  obj.detailInfo.departure_representative_place_name.visibility =
    VISIBILITY.DISABLE
  obj.detailInfo.arrival_representative_place_name.visibility =
    VISIBILITY.DISABLE
  //最寄りIC
  obj.detailInfo.departure_ic_place_name.visibility = VISIBILITY.DISABLE
  obj.detailInfo.arrival_ic_place_name.visibility = VISIBILITY.DISABLE

  // <departure>
  //zipcode,Addr01,Addr02
  obj.detailInfo.departure_place_code.visibility = VISIBILITY.DISABLE
  obj.detailInfo.departure_place_name.visibility = VISIBILITY.DISABLE
  obj.detailInfo.departure_postal_code.visibility = VISIBILITY.DISABLE
  obj.detailInfo.departure_address_1.visibility = VISIBILITY.DISABLE
  obj.detailInfo.departure_address_2.visibility = VISIBILITY.DISABLE

  // <arrival>
  //zipcode,Addr01,Addr02
  obj.detailInfo.arrival_place_code.visibility = VISIBILITY.DISABLE
  obj.detailInfo.arrival_place_name.visibility = VISIBILITY.DISABLE
  obj.detailInfo.arrival_postal_code.visibility = VISIBILITY.DISABLE
  obj.detailInfo.arrival_address_1.visibility = VISIBILITY.DISABLE
  obj.detailInfo.arrival_address_2.visibility = VISIBILITY.DISABLE

  // ロール＝OWNERの場合
  // 荷主はログインユーザの部署を設定し変更不可
  let loginUserRoleId = obj.$store.getters['authentication/getRoleId']
  if (loginUserRoleId == USER_EROLE.OWNER) {
    obj.detailInfo.sub_modal_owner.visibility = VISIBILITY.DISABLE
    obj.formData.ownerId =
      obj.$store.getters['authentication/getUserDepartmentId']
    obj.formData.ownerCode =
      obj.$store.getters['authentication/getUserDepartmentCode']
    obj.formData.ownerName =
      obj.$store.getters['authentication/getUserDepartmentName']

    obj.oriData.ownerId =
      obj.$store.getters['authentication/getUserDepartmentId']
    obj.oriData.ownerCode =
      obj.$store.getters['authentication/getUserDepartmentCode']
    obj.oriData.ownerName =
      obj.$store.getters['authentication/getUserDepartmentName']
  }
}

/**
 * 画面項目のDisable属性設定
 */
function setDisplayScreenItemsDisable(obj) {
  //初期値：非活性(取消データのみ有効)
  obj.detailCtrlInfo.delete.visibility = VISIBILITY.DISABLE

  if (obj.screenKbn == DETAIL_SCREEN_KBN.DETAIL) {
    // 受付番号
    obj.detailInfo.receipt_no.visibility = VISIBILITY.DISABLE

    // 取消データ（受注オーダの取消フラグ=1)の場合は、全項目非活性とする
    if (obj.formData.canceled == CANCEL_FLAG.CANCEL) {
      // receiptNo
      obj.detailInfo.receipt_no.visibility = VISIBILITY.DISABLE
      // blockCode
      obj.detailInfo.block_code.visibility = VISIBILITY.DISABLE

      // // <departure>
      // //zipcode,Addr01,Addr02
      obj.detailInfo.sub_modal_departure_place.visibility = VISIBILITY.DISABLE

      if (obj.formData.departureBeginTime == null)
        obj.formData.departureBeginTime = ''
      if (obj.formData.departureEndTime == null)
        obj.formData.departureEndTime = ''

      obj.detailInfo.departure_begin_date.visibility = VISIBILITY.DISABLE
      obj.detailInfo.departure_end_date.visibility = VISIBILITY.DISABLE
      obj.detailInfo.departure_begin_time.visibility = VISIBILITY.DISABLE
      obj.detailInfo.departure_end_time.visibility = VISIBILITY.DISABLE

      // 出発場所指定フラグ
      // 出発時間指定区分
      // 出発時間厳格度
      obj.detailInfo.departure_place_assign_flag.visibility = VISIBILITY.DISABLE
      obj.detailInfo.departure_time_assign_kind.visibility = VISIBILITY.DISABLE
      obj.detailInfo.departure_time_strict.visibility = VISIBILITY.DISABLE

      // // <arrival>
      // //zipcode,Addr01,Addr02
      obj.detailInfo.sub_modal_arrival_place.visibility = VISIBILITY.DISABLE

      if (obj.formData.arrivalBeginTime == null)
        obj.formData.arrivalBeginTime = ''
      if (obj.formData.arrivalEndTime == null) obj.formData.arrivalEndTime = ''

      obj.detailInfo.arrival_begin_date.visibility = VISIBILITY.DISABLE
      obj.detailInfo.arrival_end_date.visibility = VISIBILITY.DISABLE
      obj.detailInfo.arrival_begin_time.visibility = VISIBILITY.DISABLE
      obj.detailInfo.arrival_end_time.visibility = VISIBILITY.DISABLE

      // 搬入時間指定区分
      // 搬入時間厳格度
      obj.detailInfo.arrival_time_assign_kind.visibility = VISIBILITY.DISABLE
      obj.detailInfo.arrival_time_strict.visibility = VISIBILITY.DISABLE

      // owner, distributor, consignee, carrier
      if (obj.formData.ownerCode == null) obj.formData.ownerCode = ''
      if (obj.formData.distributorCode == null)
        obj.formData.distributorCode = ''
      if (obj.formData.consigneeCode == null) obj.formData.consigneeCode = ''
      if (obj.formData.assignCarrierCode == null)
        obj.formData.assignCarrierCode = ''

      obj.detailInfo.sub_modal_owner.visibility = VISIBILITY.DISABLE
      obj.detailInfo.sub_modal_distributor.visibility = VISIBILITY.DISABLE
      obj.detailInfo.sub_modal_consignee.visibility = VISIBILITY.DISABLE
      obj.detailInfo.sub_modal_assign_carrier.visibility = VISIBILITY.DISABLE

      // 備考
      if (obj.formData.note == null) obj.formData.note = ''
      obj.detailInfo.note.visibility = VISIBILITY.DISABLE

      if (obj.formData.description01 == null) obj.formData.description01 = ''
      if (obj.formData.description02 == null) obj.formData.description02 = ''
      if (obj.formData.description03 == null) obj.formData.description03 = ''
      if (obj.formData.description04 == null) obj.formData.description04 = ''
      if (obj.formData.description05 == null) obj.formData.description05 = ''
      obj.detailInfo.description01.visibility = VISIBILITY.DISABLE
      obj.detailInfo.description02.visibility = VISIBILITY.DISABLE
      obj.detailInfo.description03.visibility = VISIBILITY.DISABLE
      obj.detailInfo.description04.visibility = VISIBILITY.DISABLE
      obj.detailInfo.description05.visibility = VISIBILITY.DISABLE

      // // 配車計画グループ
      // obj.detailInfo.planning_group_code.visibility = VISIBILITY.DISABLE

      // 輸送形態区分
      // 輸送距離区分
      obj.detailInfo.transport_type_kind.visibility = VISIBILITY.DISABLE
      obj.detailInfo.transport_distance_kind.visibility = VISIBILITY.DISABLE

      // reserve01 - 20
      if (obj.formData.reserve01 == null) obj.formData.reserve01 = ''
      if (obj.formData.reserve02 == null) obj.formData.reserve02 = ''
      if (obj.formData.reserve03 == null) obj.formData.reserve03 = ''
      if (obj.formData.reserve04 == null) obj.formData.reserve04 = ''
      if (obj.formData.reserve05 == null) obj.formData.reserve05 = ''
      if (obj.formData.reserve06 == null) obj.formData.reserve06 = ''
      if (obj.formData.reserve07 == null) obj.formData.reserve07 = ''
      if (obj.formData.reserve08 == null) obj.formData.reserve08 = ''
      if (obj.formData.reserve09 == null) obj.formData.reserve09 = ''
      if (obj.formData.reserve10 == null) obj.formData.reserve10 = ''
      if (obj.formData.reserve11 == null) obj.formData.reserve11 = ''
      if (obj.formData.reserve12 == null) obj.formData.reserve12 = ''
      if (obj.formData.reserve13 == null) obj.formData.reserve13 = ''
      if (obj.formData.reserve14 == null) obj.formData.reserve14 = ''
      if (obj.formData.reserve15 == null) obj.formData.reserve15 = ''
      if (obj.formData.reserve16 == null) obj.formData.reserve16 = ''
      if (obj.formData.reserve17 == null) obj.formData.reserve17 = ''
      if (obj.formData.reserve18 == null) obj.formData.reserve18 = ''
      if (obj.formData.reserve19 == null) obj.formData.reserve19 = ''
      if (obj.formData.reserve20 == null) obj.formData.reserve20 = ''
      if (obj.formData.reserve21 == null) obj.formData.reserve21 = ''
      if (obj.formData.reserve22 == null) obj.formData.reserve22 = ''
      if (obj.formData.reserve23 == null) obj.formData.reserve23 = ''
      if (obj.formData.reserve24 == null) obj.formData.reserve24 = ''
      if (obj.formData.reserve25 == null) obj.formData.reserve25 = ''
      if (obj.formData.reserve26 == null) obj.formData.reserve26 = ''
      if (obj.formData.reserve27 == null) obj.formData.reserve27 = ''
      if (obj.formData.reserve28 == null) obj.formData.reserve28 = ''
      if (obj.formData.reserve29 == null) obj.formData.reserve29 = ''
      if (obj.formData.reserve30 == null) obj.formData.reserve30 = ''
      if (obj.formData.reserve31 == null) obj.formData.reserve31 = ''
      if (obj.formData.reserve32 == null) obj.formData.reserve32 = ''
      if (obj.formData.reserve33 == null) obj.formData.reserve33 = ''
      if (obj.formData.reserve34 == null) obj.formData.reserve34 = ''
      if (obj.formData.reserve35 == null) obj.formData.reserve35 = ''
      if (obj.formData.reserve36 == null) obj.formData.reserve36 = ''
      if (obj.formData.reserve37 == null) obj.formData.reserve37 = ''
      if (obj.formData.reserve38 == null) obj.formData.reserve38 = ''
      if (obj.formData.reserve39 == null) obj.formData.reserve39 = ''
      if (obj.formData.reserve40 == null) obj.formData.reserve40 = ''
      if (obj.formData.reserve41 == null) obj.formData.reserve41 = ''
      if (obj.formData.reserve42 == null) obj.formData.reserve42 = ''
      if (obj.formData.reserve43 == null) obj.formData.reserve43 = ''
      if (obj.formData.reserve44 == null) obj.formData.reserve44 = ''
      if (obj.formData.reserve45 == null) obj.formData.reserve45 = ''
      if (obj.formData.reserve46 == null) obj.formData.reserve46 = ''
      if (obj.formData.reserve47 == null) obj.formData.reserve47 = ''
      if (obj.formData.reserve48 == null) obj.formData.reserve48 = ''
      if (obj.formData.reserve59 == null) obj.formData.reserve49 = ''
      if (obj.formData.reserve50 == null) obj.formData.reserve50 = ''
      if (obj.formData.reserve51 == null) obj.formData.reserve51 = ''
      if (obj.formData.reserve52 == null) obj.formData.reserve52 = ''
      if (obj.formData.reserve53 == null) obj.formData.reserve53 = ''
      if (obj.formData.reserve54 == null) obj.formData.reserve54 = ''
      if (obj.formData.reserve55 == null) obj.formData.reserve55 = ''
      if (obj.formData.reserve56 == null) obj.formData.reserve56 = ''
      if (obj.formData.reserve57 == null) obj.formData.reserve57 = ''
      if (obj.formData.reserve58 == null) obj.formData.reserve58 = ''
      if (obj.formData.reserve59 == null) obj.formData.reserve59 = ''
      if (obj.formData.reserve60 == null) obj.formData.reserve60 = ''
      if (obj.formData.reserve61 == null) obj.formData.reserve61 = ''
      if (obj.formData.reserve62 == null) obj.formData.reserve62 = ''
      if (obj.formData.reserve63 == null) obj.formData.reserve63 = ''
      if (obj.formData.reserve64 == null) obj.formData.reserve64 = ''
      if (obj.formData.reserve65 == null) obj.formData.reserve65 = ''
      if (obj.formData.reserve66 == null) obj.formData.reserve66 = ''
      if (obj.formData.reserve67 == null) obj.formData.reserve67 = ''
      if (obj.formData.reserve68 == null) obj.formData.reserve68 = ''
      if (obj.formData.reserve69 == null) obj.formData.reserve69 = ''
      if (obj.formData.reserve70 == null) obj.formData.reserve70 = ''
      if (obj.formData.reserve71 == null) obj.formData.reserve71 = ''
      if (obj.formData.reserve72 == null) obj.formData.reserve72 = ''
      if (obj.formData.reserve73 == null) obj.formData.reserve73 = ''
      if (obj.formData.reserve74 == null) obj.formData.reserve74 = ''
      if (obj.formData.reserve75 == null) obj.formData.reserve75 = ''
      if (obj.formData.reserve76 == null) obj.formData.reserve76 = ''
      if (obj.formData.reserve77 == null) obj.formData.reserve77 = ''
      if (obj.formData.reserve78 == null) obj.formData.reserve78 = ''
      if (obj.formData.reserve79 == null) obj.formData.reserve79 = ''
      if (obj.formData.reserve80 == null) obj.formData.reserve80 = ''
      if (obj.formData.reserve81 == null) obj.formData.reserve81 = ''
      if (obj.formData.reserve82 == null) obj.formData.reserve82 = ''
      if (obj.formData.reserve83 == null) obj.formData.reserve83 = ''
      if (obj.formData.reserve84 == null) obj.formData.reserve84 = ''
      if (obj.formData.reserve85 == null) obj.formData.reserve85 = ''
      if (obj.formData.reserve86 == null) obj.formData.reserve86 = ''
      if (obj.formData.reserve87 == null) obj.formData.reserve87 = ''
      if (obj.formData.reserve88 == null) obj.formData.reserve88 = ''
      if (obj.formData.reserve89 == null) obj.formData.reserve89 = ''
      if (obj.formData.reserve90 == null) obj.formData.reserve90 = ''
      if (obj.formData.reserve91 == null) obj.formData.reserve91 = ''
      if (obj.formData.reserve92 == null) obj.formData.reserve92 = ''
      if (obj.formData.reserve93 == null) obj.formData.reserve93 = ''
      if (obj.formData.reserve94 == null) obj.formData.reserve94 = ''
      if (obj.formData.reserve95 == null) obj.formData.reserve95 = ''
      if (obj.formData.reserve96 == null) obj.formData.reserve96 = ''
      if (obj.formData.reserve97 == null) obj.formData.reserve97 = ''
      if (obj.formData.reserve98 == null) obj.formData.reserve98 = ''
      if (obj.formData.reserve99 == null) obj.formData.reserve99 = ''
      if (obj.formData.reserve100 == null) obj.formData.reserve100 = ''
      if (obj.formData.reserve101 == null) obj.formData.reserve101 = ''
      if (obj.formData.reserve102 == null) obj.formData.reserve102 = ''
      if (obj.formData.reserve103 == null) obj.formData.reserve103 = ''
      if (obj.formData.reserve104 == null) obj.formData.reserve104 = ''
      if (obj.formData.reserve105 == null) obj.formData.reserve105 = ''
      if (obj.formData.reserve106 == null) obj.formData.reserve106 = ''
      if (obj.formData.reserve107 == null) obj.formData.reserve107 = ''
      if (obj.formData.reserve108 == null) obj.formData.reserve108 = ''
      if (obj.formData.reserve109 == null) obj.formData.reserve109 = ''
      if (obj.formData.reserve110 == null) obj.formData.reserve110 = ''
      if (obj.formData.reserve111 == null) obj.formData.reserve111 = ''
      if (obj.formData.reserve112 == null) obj.formData.reserve112 = ''
      if (obj.formData.reserve113 == null) obj.formData.reserve113 = ''
      if (obj.formData.reserve114 == null) obj.formData.reserve114 = ''
      if (obj.formData.reserve115 == null) obj.formData.reserve115 = ''
      if (obj.formData.reserve116 == null) obj.formData.reserve116 = ''
      if (obj.formData.reserve117 == null) obj.formData.reserve117 = ''
      if (obj.formData.reserve118 == null) obj.formData.reserve118 = ''
      if (obj.formData.reserve119 == null) obj.formData.reserve119 = ''
      if (obj.formData.reserve120 == null) obj.formData.reserve120 = ''
      if (obj.formData.reserve121 == null) obj.formData.reserve121 = ''
      if (obj.formData.reserve122 == null) obj.formData.reserve122 = ''
      if (obj.formData.reserve123 == null) obj.formData.reserve123 = ''
      if (obj.formData.reserve124 == null) obj.formData.reserve124 = ''
      if (obj.formData.reserve125 == null) obj.formData.reserve125 = ''
      if (obj.formData.reserve126 == null) obj.formData.reserve126 = ''
      if (obj.formData.reserve127 == null) obj.formData.reserve127 = ''
      if (obj.formData.reserve128 == null) obj.formData.reserve128 = ''
      if (obj.formData.reserve129 == null) obj.formData.reserve129 = ''
      if (obj.formData.reserve130 == null) obj.formData.reserve130 = ''
      if (obj.formData.reserve131 == null) obj.formData.reserve131 = ''
      if (obj.formData.reserve132 == null) obj.formData.reserve132 = ''
      if (obj.formData.reserve133 == null) obj.formData.reserve133 = ''
      if (obj.formData.reserve134 == null) obj.formData.reserve134 = ''
      if (obj.formData.reserve135 == null) obj.formData.reserve135 = ''
      if (obj.formData.reserve136 == null) obj.formData.reserve136 = ''
      if (obj.formData.reserve137 == null) obj.formData.reserve137 = ''
      if (obj.formData.reserve138 == null) obj.formData.reserve138 = ''
      if (obj.formData.reserve139 == null) obj.formData.reserve139 = ''
      if (obj.formData.reserve140 == null) obj.formData.reserve140 = ''

      obj.detailInfo.reserve01.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve02.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve03.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve04.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve05.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve06.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve07.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve08.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve09.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve10.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve11.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve12.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve13.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve14.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve15.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve16.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve17.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve18.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve19.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve20.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve21.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve22.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve23.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve24.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve25.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve26.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve27.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve28.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve29.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve30.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve31.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve32.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve33.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve34.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve35.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve36.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve37.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve38.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve39.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve40.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve41.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve42.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve43.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve44.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve45.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve46.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve47.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve48.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve49.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve50.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve51.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve52.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve53.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve54.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve55.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve56.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve57.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve58.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve59.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve60.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve61.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve62.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve63.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve64.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve65.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve66.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve67.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve68.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve69.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve70.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve71.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve72.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve73.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve74.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve75.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve76.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve77.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve78.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve79.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve80.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve81.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve82.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve83.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve84.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve85.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve86.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve87.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve88.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve89.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve90.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve91.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve92.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve93.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve94.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve95.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve96.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve97.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve98.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve99.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve100.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve101.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve102.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve103.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve104.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve105.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve106.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve107.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve108.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve109.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve110.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve111.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve112.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve113.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve114.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve115.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve116.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve117.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve118.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve119.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve120.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve121.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve122.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve123.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve124.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve125.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve126.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve127.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve128.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve129.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve130.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve131.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve132.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve133.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve134.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve135.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve136.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve137.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve138.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve139.visibility = VISIBILITY.DISABLE
      obj.detailInfo.reserve140.visibility = VISIBILITY.DISABLE

      //register, detail(add, edit, delete) button
      obj.detailCtrlInfo.register.visibility = VISIBILITY.DISABLE
      obj.detailCtrlInfo.add.visibility = VISIBILITY.DISABLE
      obj.detailCtrlInfo.edit.visibility = VISIBILITY.DISABLE
      obj.detailCtrlInfo.table_row_delete.visibility = VISIBILITY.DISABLE
      obj.detailCtrlInfo.product_batch.visibility = VISIBILITY.DISABLE

      // 削除ボタン：削除は取消データのみ有効。
      obj.detailCtrlInfo.delete.visibility = VISIBILITY.VISIBLE
    } // CancelFlag = CANCEL
  }
}

/**
 * 荷姿一括追加設定処理
 * @param {*} obj
 * @param {*} itemInfo
 */
function setItemInfoProductBatch(obj, itemInfo) {
  if (
    !obj.formData.receiveOrderDetails.some(
      (list) => list.product_id === itemInfo.productId
    )
  ) {
    //数量
    let quantity = 0
    if (itemInfo.quantity != null) {
      quantity = itemInfo.quantity
    }
    let highwayUseYnFlag = HIGHWAY_USE_YN_FLAG.USED
    // C条件、D条件が設定された場合、高速道路使用可否フラグ「 0 : 利用しない」設定
    if (
      itemInfo.vehicleCondition11 == VEHICLE_CONDITION.ENABLE ||
      itemInfo.vehicleCondition12 == VEHICLE_CONDITION.ENABLE
    ) {
      highwayUseYnFlag = HIGHWAY_USE_YN_FLAG.NOT_USED
    }

    let addInfo = {
      process_kind: PROCESS_KIND.INSERT, //登録
      order_detail_id: null,
      product_id: itemInfo.productId,
      product_code: itemInfo.productCode,
      product_name: itemInfo.productName,
      auto_section_divisions: itemInfo.autoSectionDivisions, // 自動区間分割可否
      tariff_product_code: itemInfo.tariffProductCode,
      quantity: quantity,
      weight: itemInfo.weight,
      volume: itemInfo.volume,
      length: itemInfo.length,
      width: itemInfo.width,
      height: itemInfo.height,
      warning_message: itemInfo.warningMessage,
      note: itemInfo.note,

      //Edit
      departure_begin_date: null,
      departure_begin_time: null,
      departure_end_date: null,
      departure_end_time: null,

      departure_place_id: null,
      departure_place_code: null,
      departure_place_name: null,
      departure_place_assign_flag: null,
      departure_time_assign_kind: null,
      departure_time_strict: null,

      // Edit
      arrival_begin_date: null,
      arrival_begin_time: null,
      arrival_end_date: null,
      arrival_end_time: null,

      arrival_place_id: null,
      arrival_place_code: null,
      arrival_place_name: null,
      arrival_time_assign_kind: null,
      arrival_time_strict: null,
      departure_condition: null,
      arrival_condition: null,
      //自動配車対象外フラグ
      //auto_planning_exclude_flag: AUTO_PLANNING_EXCLUDE_FLAG.COVERD,
      auto_planning_exclude_flag: itemInfo.autoPlanningExcludeFlag,
      auto_planning_exclude_flag_name: getMasterName(
        MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG,
        itemInfo.autoPlanningExcludeFlag
      ),
      preload_kind: null,

      preload_date: null,
      preload_begin_time: null,
      assign_trip_no: null,
      assign_drop_no: null,
      assign_vehicle_id: null,
      assign_vehicle_name: null,
      assign_vehicle_code: null,
      assign_carrier_id: null,
      assign_carrier_code: null,
      assign_carrier_name: null,
      canceled: null,
      vehicle_category: null,

      //高速道路使用可否フラグ
      highway_use_yn_flag: highwayUseYnFlag,
      highway_use_yn_flag_name: getMasterName(
        MASTER_CODE.HIGHWAY_USE_YN_FLAG,
        highwayUseYnFlag
      ),
      //オーダー距離
      order_distance: 0,
      //往復区分
      oneway_or_round: GO_OR_BACK.GOWAY, // 往路 = 0
      oneway_or_round_name: getMasterName(
        MASTER_CODE.GO_OR_BACK,
        GO_OR_BACK.GOWAY
      ),
      //2台積区分
      is_multiple_loading: IS_MULTIPLE_LOADING.LOAD_ONE, // 1台積
      is_multiple_loading_name: getMasterName(
        MASTER_CODE.IS_MULTIPLE_LOADING,
        IS_MULTIPLE_LOADING.LOAD_ONE
      ),

      vehicle_condition01: itemInfo.vehicleCondition01,
      vehicle_condition02: itemInfo.vehicleCondition02,
      vehicle_condition03: itemInfo.vehicleCondition03,
      vehicle_condition04: itemInfo.vehicleCondition04,
      vehicle_condition05: itemInfo.vehicleCondition05,
      vehicle_condition06: itemInfo.vehicleCondition06,
      vehicle_condition07: itemInfo.vehicleCondition07,
      vehicle_condition08: itemInfo.vehicleCondition08,
      vehicle_condition09: itemInfo.vehicleCondition09,
      vehicle_condition10: itemInfo.vehicleCondition10,
      vehicle_condition11: itemInfo.vehicleCondition11,
      vehicle_condition12: itemInfo.vehicleCondition12,
      vehicle_condition13: itemInfo.vehicleCondition13,
      vehicle_condition14: itemInfo.vehicleCondition14,
      vehicle_condition15: itemInfo.vehicleCondition15,
      vehicle_condition16: itemInfo.vehicleCondition16,
      vehicle_condition17: itemInfo.vehicleCondition17,
      vehicle_condition18: itemInfo.vehicleCondition18,
      vehicle_condition19: itemInfo.vehicleCondition19,
      vehicle_condition20: itemInfo.vehicleCondition20,

      reserve01: null,
      reserve02: null,
      reserve03: null,
      reserve04: null,
      reserve05: null,
      reserve06: null,
      reserve07: null,
      reserve08: null,
      reserve09: null,
      reserve10: null,
      reserve11: null,
      reserve12: null,
      reserve13: null,
      reserve14: null,
      reserve15: null,
      reserve16: null,
      reserve17: null,
      reserve18: null,
      reserve19: null,
      reserve20: null,
      reserve21: null,
      reserve22: null,
      reserve23: null,
      reserve24: null,
      reserve25: null,
      reserve26: null,
      reserve27: null,
      reserve28: null,
      reserve29: null,
      reserve30: null,

      is_simulation_data: IS_SIMULATION_DATA.ETC,
      is_simulation_data_name: getMasterName(
        MASTER_CODE.IS_SIMULATION_DATA,
        IS_SIMULATION_DATA.ETC
      ),
      process_status: PROCESS_STATUS.DONE, // 0：完了
      process_status_name: getMasterName(
        MASTER_CODE.PROCESS_STATUS,
        PROCESS_STATUS.ETC
      ),
      lock_flag: LOCK_FLAG.NORMAL, // 0：通常
      lock_flag_name: getMasterName(MASTER_CODE.LOCK_FLAG, LOCK_FLAG.NORMAL),
      registration_time: null,
      registration_user: null,
      update_by_trigger: null,
      update_time: null,
      update_user: null,
      version: null,
      deleted: 0,
    }
    obj.formData.receiveOrderDetails.push(addInfo)
  }
  obj.tableItems = obj.formData.receiveOrderDetails
}

/**
 * 荷姿パターンIDから荷姿パターン情報を取得する。
 * @param {*} obj
 * @param {*} productPatternId
 */
function getProductInfos(obj, productPatternId) {
  obj.$store.dispatch('init/setServiceLoading')
  ProductPatternService.get(productPatternId)
    .then(
      (response) => {
        let productPattern = response.data
        let patterns = productPattern.productPatternDetails
        if (patterns.length > 0) {
          patterns.forEach((ptProductInfo) => {
            // 荷姿IDから荷姿情報を取得
            ProductService.get(ptProductInfo.productId).then(
              (responseProduct) => {
                let productInfo = responseProduct.data
                if (productInfo != null) {
                  setItemInfoProductBatch(obj, productInfo)
                }
              },
              (e) => {
                console.log(e.response.data.message)
              }
            )
          })
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 行ロック
 * @param {*} obj
 */
export function tableRowLock(obj) {
  // 複数個削除
  let codeName = getMasterName(MASTER_CODE.LOCK_FLAG, LOCK_FLAG.LOCK)
  for (let info of obj.selectedItem) {
    info.lock_flag = LOCK_FLAG.LOCK
    info.lock_flag_name = codeName
  }
}

/**
 * 行ロック解除
 * @param {*} obj
 */
export function tableRowUnLock(obj) {
  // 複数個
  let codeName = getMasterName(MASTER_CODE.LOCK_FLAG, LOCK_FLAG.NORMAL)
  for (let info of obj.selectedItem) {
    info.lock_flag = LOCK_FLAG.NORMAL
    info.lock_flag_name = codeName
  }
}
